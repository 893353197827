import { documentSubTitle, spaceStackLinesSideEffect } from '..';
import { LegalJurisdiction } from '../constants';
import { bold, clause, doubleQuote, formatAct, ol, quote, stack } from '../formatters/clauses';
import { setNumberingSideEffect } from '../formatters/setNumberingSideEffect';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';

export function generalConditionsForSaleContract(brand: EntityBrandFormConfig) {
  return [
    documentSubTitle('General Conditions', { pageBreak: true }),
    {
      style: 'listMarker',
      ol: spaceStackLinesSideEffect([
        clause('Definitions',
          'In this Contract, unless the context otherwise requires:',
          `${quote(bold('Act'))} means the ${formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')};`,
          `${quote(bold('Additional Condition'))} means any provision in or annexure to this Contract so titled;`,
          `${quote(bold('Agent'))} means the agent for the Vendor registered under the ${formatAct(LegalJurisdiction.SouthAustralia, 'LandAgentsAct1994')} as shown in the header on page 1 of this Contract;`,
          `${quote(bold('ATO'))} means Australian Taxation Office;`,
          `${quote(bold('Business Day'))} means any day other than a Saturday, Sunday or public holiday in South Australia;`,
          `${quote(bold('Community Lot'))} means a community lot or community strata lot in a community plan deposited or intended to be deposited at Land Services SA;`,
          `${quote(bold('Contract'))} means this Contract between the Vendor and Purchaser in respect of the Property and includes the Schedule, General Conditions and any Special Conditions, Additional Conditions and annexures;`,
          `${quote(bold('Contract Date'))} means the date of this Contract;`,
          `${quote(bold('Default Rate'))} means the cash rate published by the Reserve Bank of Australia on the day of default plus seven percentage points;`,
          `${quote(bold('Deposit'))} means the amount payable as a deposit specified in Item 5;`,
          `${quote(bold('ECNL'))} means the Electronic Conveyancing National Law (South Australia) as defined in the ${formatAct(LegalJurisdiction.SouthAustralia, 'ElectronicNationalLawSouthAustraliaAct2013')};`,
          `${quote(bold('Electronic Workspace'))} means a shared electronic workspace generated by the ELN;`,
          `${quote(bold('ELN'))} and ${quote(bold('ELNO'))} have the meanings given to them in the ECNL;`,
          `${quote(bold('Encumbrance'))} includes a mortgage, charge, bill of sale, lien, pledge, easement, restrictive covenant, writ, warrant or caveat or similar right or interest;`,
          `${quote(bold('Exclusions'))} means the excluded items specified in Item 7 under the Excl. Column;`,
          `${quote(bold('Form 1'))} means a vendor’s statement as prescribed under the Act;`,
          `${quote(bold('General Condition'))} means any provision in this Contract so titled;`,
          `${quote(bold('GST Act'))} means ${formatAct(LegalJurisdiction.Commonwealth, 'ANewTaxSystemGoodsAndServicesTaxAct1999')};`,
          `${quote(bold('GST Conditions of Sale'))} means the conditions specified in Item 4;`,
          `${quote(bold('Inclusions'))} means the included items specified in Item 7 under the Incl. Column;`,
          `${quote(bold('Income'))} means all rent, benefits and other monies received or receivable arising from the rights and use of the Property;`,
          `${quote(bold('Outgoings'))} includes:`,
          ol(
            ol(
              'all rates, taxes, levies, assessments and charges or other outgoings (periodical or otherwise) chargeable or payable in respect of the Property;',
              `if the Property includes a Strata Unit or Community Lot, all contributions levied by the strata corporation and payable under the ${formatAct(LegalJurisdiction.SouthAustralia, 'StrataTitlesAct1988')} or by the community corporation under the ${formatAct(LegalJurisdiction.SouthAustralia, 'CommunityTitlesAct1996')}, including any outstanding contributions of the Vendor to the strata corporation or the community corporation; and`,
              'all rents, fees and other periodical amounts payable under any lease, licence or agreement;',
              'land tax adjusted on the basis that the land constitutes a single holding and at general rates; and',
              'water use and consumption;'
            )
          ),
          `${quote(bold('Participation Rules'))} means the South Australian Participation Rules for Electronic Conveyancing as determined by the Registrar-General in accordance with section 23 of the ECNL;`,
          `${quote(bold('PDI Act'))} means the ${formatAct(LegalJurisdiction.SouthAustralia, 'PlanningDevelopmentAndInfrastructureAct2016')};`,
          `${quote(bold('Permitted Encumbrances'))} means the Encumbrances specified under the heading ${quote('Permitted Encumbrances')} in Item 7;`,
          `${quote(bold('Pool'))} means a ${quote('swimming pool')} (as defined in the PDI Act) and located on the Property;`,
          `${quote(bold('PPS Act'))} means the ${formatAct(LegalJurisdiction.Commonwealth, 'PersonalPropertySecuritiesAct2009')};`,
          `${quote(bold('Property'))} means:`,
          ol(
            ol(
              'the freehold land (including a Strata Unit or Community Lot) or leasehold land specified in Item 1; and',
              'all buildings and other improvements on the land, including all fixtures and fittings and Inclusions, but not the Exclusions;'
            )
          ),
          `${quote(bold('Purchase Price'))} means the amount specified in Item 5 subject to any adjustment as this Contract requires;`,
          `${quote(bold('Purchaser'))} means the party specified in Item 3;`,
          `${quote(bold('Representative'))} means a law practice under the ${formatAct(LegalJurisdiction.SouthAustralia, 'LegalPractitionersAct1981')} or a conveyancer under the ${formatAct(LegalJurisdiction.SouthAustralia, 'ConveyancersAct1994')} acting for a party to this Contract;`,
          `${quote(bold('Settlement'))} means the completion of the sale and purchase of the Property from the Vendor to the Purchaser in accordance with this Contract;`,
          `${quote(bold('Settlement Date'))} means the date for settlement specified in or calculated in accordance with Item 6;`,
          `${quote(bold('Special Condition'))} means any provision in this Contract so titled;`,
          `${quote(bold('Stakeholder'))} means either the Agent or the Vendor’s Representative as nominated by the Vendor;`,
          `${quote(bold('Strata Unit'))} means a unit on a strata plan registered at Land Services SA;`,
          `${quote(bold('Tax Act'))} means the ${formatAct(LegalJurisdiction.Commonwealth, 'TaxationAdministrationAct1953')} and the ${formatAct(LegalJurisdiction.Commonwealth, 'IncomeTaxAssessmentAct1997')}, or any one of them where applicable;`,
          `${quote(bold('Tenancy'))} means the tenancies specified to continue after settlement in Item 7;`,
          `${quote(bold('Vendor'))} means the party specified in Item 2; and`,
          `${quote(bold('Vendor’s Works'))} means any works to be carried out by the Vendor prior to Settlement specified in Item 7.`,
        ),
        clause('Interpretation',
          'In this Contract, unless something else is clearly intended:',
          ol(
            'a reference to:',
            ol(
              'a party to this Contract includes that party’s executors, administrators, substitutes, successors and assigns;',
              'any legislation or any provision of any legislation includes:',
              ol(
                'all regulations, orders or instruments issued under the legislation or provision; and',
                'any modification, consolidation, amendment, re-enactment, replacement or codification of such legislation or provision;'
              ),
              `${quote('including')} and similar expressions are not and must not be treated as words of limitation;`,
              `${quote('notice')} or ${quote('notify')} means written notice given in the manner provided in this document for the service of notices;`,
              `${quote('Person')} includes corporations, firms, unincorporated bodies, authorities and instrumentalities;`,
              `an ${quote('Item')} means an Item in the Schedule;`,
              `${quote('midnight')} means 11:59:59pm of that day; and`,
              'time means the legal time in South Australia;'
            ),
            'words or expressions importing the singular include the plural and vice versa;',
            'where:',
            ol(
              'two or more Persons are named as a party in this Contract, it means those persons jointly and severally;',
              'an act would be required to be done, or a time limit or period would expire, on a day which is not a Business Day, the act may be done, or the limit or period will expire, on the following Business Day;',
              'there is anything that is due to be completed on a particular day, it will deemed to be by midnight of that day, unless otherwise specified in writing between the parties;',
              'a term is denoted in bold quotation marks within a General Condition, Special Condition or Additional Condition, that is a defined term for the purpose of the condition in which it appears only; and'
            ),
            'a checkbox will be deemed to have been selected where the intention is clear, including where a tick or a cross are marked in the checkbox.'
          )
        ),
        clause('Sale',
          'The Vendor agrees to sell to the Purchaser who agrees to buy the Property for the Purchase Price subject to the Permitted Encumbrances and otherwise on the terms of this Contract.'
        ),
        clause('Deposit',
          ol(
            'The Purchaser must pay the Deposit to the Stakeholder on the date specified in Item 5.',
            'The Stakeholder will hold the Deposit subject to the terms of this Contract.',
            `If the Vendor agrees, the Deposit may be paid by way of ${quote('Deposit Bond')} or ${quote('Deposit Guarantee')} guaranteed by a registered insurance corporation or bank and otherwise on terms acceptable to the Vendor.`
          )
        ),
        clause('Settlement',
          ol(
            'The parties agree that Settlement will take place on the Settlement Date.',
            'At Settlement, the Purchaser must pay to the Vendor the Purchase Price (less any Deposit paid and subject to any adjustments under this Contract).'
          )
        ),
        clause('Settlement – Outgoings and Income',
          ol(
            'Subject to General Condition 16.4(b), all Outgoings and Income relating to the Property will be:',
            ol(
              'apportioned on a daily basis; and',
              'adjusted as between the Vendor and Purchaser to midnight on the day prior to Settlement.'
            ),
            'Up to the Settlement Date, the Vendor will be responsible for all Outgoings and receive all Income.',
            'On and from the Settlement Date, the Purchaser will be responsible for all Outgoings and receive all Income.',
            'On or before Settlement, in respect of those Outgoings to be adjusted between the parties under General Condition 6.1, the Vendor must pay to the relevant authority the Outgoings amount for the current financial year in which Settlement occurs, including any outstanding Outgoings amounts owing to an authority, except in respect of sewer and water rates and charges, which are only required to be paid until the end of the quarter in which Settlement occurs.',
            'Where the Property is connected to a water supply and there is water consumption at the Property:',
            ol(
              'the Vendor must procure a water meter reading for the Property as close as reasonably possible to the Settlement Date;',
              'the water consumption is to be adjusted on the water meter reading supplied by the Vendor prior to Settlement pursuant to General Condition 6.1; and',
              'if no water meter reading is supplied prior to Settlement, the Vendor’s Representative (or the Stakeholder if there is no Vendor’s Representative) must hold $400.00 in trust on behalf of the Vendor until a water meter reading is provided and an adjustment agreed between the parties.'
            ),
            'The parties may settle under protest if there is a dispute as to any amount payable under this Contract at Settlement.'
          )
        ),
        clause('Possession',
          ol(
            'Subject to the Purchaser complying with all the Purchaser’s obligations under this Contract, the Purchaser will be entitled to, and the Vendor will give, vacant possession of the Property to the Purchaser, subject only to the Tenancies.',
            'Prior to the Settlement Date, the Vendor must in a proper and workmanlike manner:',
            ol(
              'remove all Exclusions from the Property and make good any damage caused; and',
              'carry out any Vendor’s Works.'
            ),
            'The Purchaser is not entitled to access or possession of the Property until Settlement unless otherwise agreed in writing by the Vendor.',
            'If requested by the Vendor, the Purchaser must execute a licence to occupy with the Vendor prior to taking possession of the Property prior to Settlement.',
            'A breach of the terms of the licence to occupy will be deemed a breach of a term of this Contract.'
          )
        ),
        clause('Property risk and acknowledgements',
          ol(
            'The Purchaser acknowledges and agrees that from the Contract Date, the Property is at the Purchaser’s risk.',
            'Without limiting General Condition 8.1 and subject to the Vendor’s compliance with General Condition 7.2, the Purchaser is responsible for the cost of any repairs or damage to the Property, including any electrical, mechanical or structural issues arising after the Contract Date.',
            'The Vendor must:',
            ol(
              'reasonably maintain and use the Property until Settlement;',
              'promptly notify the Purchaser of any breakdown or damage to the Property that requires attention; and',
              'provide the Property to the Purchaser at Settlement in its condition and state of repair (including any defects) that the Property was in at the Contract Date, subject to fair, wear and tear.'
            ),
            'The Vendor does not warrant that any fixtures, fittings, improvements or Inclusions are in working order.',
            stack(
              'The Purchaser must not:',
              ol(
                'subject to a breach of General Condition 8.3, make any objection or claim for compensation for any loss or damage it incurs; or',
                'claim any right to rescind, terminate or delay Settlement of this Contract,'
              ),
              'arising out of the condition or state of repair of the Property or any damage or deterioration to the Property occurring between the Contract Date and the Settlement Date.'
            ),
            stack(
              'The Vendor acknowledges that:',
              ol(
                stack(
                  'on or before Settlement:',
                  ol(
                    'if there is a Pool on the Property, the Vendor must ensure that the Pool complies with all pool safety features; and',
                    'the Vendor must ensure that the Property complies with any smoke alarm requirements,'
                  ),
                  'as required by the PDI Act; and '
                ),
                'any works that need to be done to comply with General Condition 8.6(a) will be done at the Vendor’s cost and in a proper and workmanlike manner reasonably consistent with the character of the Property.'
              )
            )
          )
        ),
        clause('Misdescription',
          'The parties agree that, in respect of any error or misdescription of the Property, the Purchaser is:',
          ol(
            'entitled to make a claim for compensation from the Vendor for any loss or damage it incurs where the Purchaser notifies the Vendor of any such claim within 10 Business Days of Settlement; and',
            'not entitled to terminate the Contract.'
          )
        ),
        clause('Vendor’s warranties - general',
          'As far as the Vendor is aware and except as otherwise stated in this Contract or the Form 1 or notified to the Purchaser prior to the Contract Date, the Vendor warrants that there are no:',
          ol(
            'outstanding or impending demands, orders or requisitions of any competent authority relating to the Property;',
            'amounts owing to any competent authority in respect of works performed, or to be performed, in relation to the Property;',
            'proposals for the re-alignment, widening or alteration of the level of any road adjoining the Property by any competent authority that would materially affect the Property or the use of it;',
            'notices of resumption or intended resumption of the Property, or any part of it, by any competent authority;',
            'except in relation to a Strata Unit or Community Lot or as detailed in the Certificate of Title to the Property, sewers, drains, pipes, cables or other installations passing through the Property providing the services to other land;',
            `outstanding or impending notices, demands or liabilities to join in or contribute to the construction or repair of a dividing fence between the Property and any adjoining land under the ${formatAct(LegalJurisdiction.SouthAustralia, 'FencesAct1975')} or otherwise;`,
            'dividing fences and walls not on the boundaries of the Property;',
            'encroachments onto the Property by any building or structure from adjoining land;',
            'improvements outside the boundaries of the Property; or',
            'improvements or alterations that have been erected without the consent of the competent authority.'
          )
        ),
        clause('Vendor’s warranties - strata or community title',
          ol(
            'In addition to the warranties in General Condition 10, where the Property is a Strata Unit or Community Lot, as far as the Vendor is aware and except as otherwise stated in this Contract or the Form 1 or notified to the Purchaser prior to the Contract Date, the Vendor warrants that there are no:',
            ol(
              'circumstances relating to the common property that will materially affect the Purchaser’s use or enjoyment of the Property or the common property except as apparent on inspection or mentioned in the strata plan or the community plan or a schedule;',
              'current or pending proceedings relating to the strata corporation or the community corporation or to the Strata Unit or Community Lot;',
              'unsatisfied judgments or orders against the strata corporation or the community corporation;',
              'proposals, or any proceedings or procedures initiated, for:',
              ol(
                'the variation of the schedule of entitlements in respect of the strata corporation or the community corporation;',
                'the grant, variation or surrender of any easements or restrictive covenants which affect the Property or common property; or',
                'the transfer, lease or licence of any part of the common property; and'
              ),
              'current or pending proposals to pass a resolution of the strata corporation or the community corporation.'
            ),
            'On or after the Contract Date, the Vendor undertakes to:',
            ol(
              'advise the Purchaser of any pending meetings relating to the strata corporation or the community corporation not less than five Business Days prior to any meeting; and',
              'to the extent the Purchaser is not in default under the terms of this Contract at the relevant time, vote only in accordance with the reasonable directions of the Purchaser at any meeting held before Settlement.'
            )
          )
        ),
        clause('Tenancies',
          'Where the Property being sold is subject to a Tenancy:',
          ol(
            `At any time, whether before or after Settlement, the Vendor or the Purchaser may give notice to the other party requiring the parties to enter into a Deed of Assignment (${quote(bold('Deed'))}) in relation to the Tenancies.`,
            'The Deed will be prepared by, and at the cost of, the party who requested it and on generally accepted terms for an assignment of a Tenancy as part of the sale of land.',
            'Each party must, at their own cost, act reasonably in its negotiation of the terms of the Deed, and sign the Deed within five Business Days of the agreement of its terms.',
            'Prior to Settlement, the Vendor must not agree to grant a new lease or vary or agree to surrender an existing Tenancy without the prior written consent of the Purchaser, with such consent not to be unreasonably withheld or delayed.'
          )
        ),
        clause('Crown Lease',
          'When the Property is wholly or partially held under a Crown Lease:',
          ol(
            'The Property is sold subject to the terms and conditions of the Crown Lease.',
            'The Crown Lease will be taken to have been validly granted and production of the receipt for the current year’s rent (or instalment of rent) will be accepted by the Purchaser as sufficient proof that all the terms of the Crown Lease have been observed.',
            'If the consent of the Minister or other authority to the transfer of the Crown Lease is required, then:',
            ol(
              'the Vendor and the Purchaser must apply for, and use their best endeavours to obtain consent as soon as possible after the Contract Date; and',
              'the Purchaser is responsible for the costs of and incidental to the application for consent.'
            ),
            'If consent is refused, then this Contract automatically terminates on receipt of the refusal to consent.  The Vendor may deduct any direct costs and expenses it incurs in the application for consent from any Deposit held.'

          )
        ),
        clause('Conveyancing – electronic',
          ol(
            stack(
              'Subject to General Condition 15.1, the parties agree to:',
              ol(
                'effect Settlement on the Settlement Date; and',
                'lodge the instruments necessary to record the Purchaser as registered proprietor of the Property,'
              ),
              'electronically using an ELN, in accordance with the ECNL and the Participation Rules.'
            ),
            'Each party must:',
            ol(
              'be, or engage a Representative who is, a subscriber for the purposes of the ECNL; and',
              'ensure that all other persons for whom that party is responsible and who are associated with this transaction are, or engage, a subscriber for the purposes of the ECNL.'
            ),
            'The Vendor must as soon as reasonably practicable following the Contract Date and in any event no later than 10 Business Days before the Settlement Date, open an Electronic Workspace.',
            'The Vendor must nominate a time of day for Settlement to occur on the Settlement Date in the Electronic Workspace at least three Business Days before the Settlement Date.',
            'The parties must do all things reasonably necessary to complete the Electronic Workspace in a timely manner in order to ensure that Settlement occurs on the Settlement Date.',
            'Settlement occurs when the Electronic Workspace records that:',
            ol(
              'the exchange of funds or value between financial institutions in accordance with the instructions of the parties has occurred; or',
              'if there is no exchange of funds or value, the documents necessary to enable the Purchaser to become the registered proprietor of the Property have been lodged on the Electronic Workspace.'
            ),
            'Each party must do everything reasonably necessary to assist the other party to trace and identify the recipient of any mistaken payment and to recover the mistaken payment.',
            'If Settlement is unable to occur on the Settlement Date due to an electronic or computer system failure of Land Services SA, the Reserve Bank of Australia or the ELNO, the failure to settle will not constitute a breach under this Contract.'
          )
        ),
        clause('Conveyancing - paper',
          ol(
            stack(
              'If:',
              ol(
                'electronic conveyancing is not possible for this transaction pursuant to the current policies and procedures of the Office of the Registrar General; or',
                'the parties otherwise agree,'
              ),
              'Settlement will occur at Land Services SA and this General Condition 15 applies.'
            ),
            'The Purchaser will, not less than five Business Days prior to the Settlement Date and at the Purchaser’s expense, tender a transfer of the Property to the Vendor.',
            'The Vendor may allow the Purchaser possession of the executed transfer in escrow for stamping prior to Settlement. However, the transfer will remain the property of the Vendor.',
            'At Settlement:',
            ol(
              'the Purchaser must pay to the Vendor by bank cheque the balance of the Purchase Price and any other monies payable by the Purchaser on Settlement; and',
              'the Vendor must provide the transfer in registrable form for Land Services SA together with any applications, transfers, instruments, declarations or documents required to be tendered at Settlement to enable the conveyance of clear title to the Purchaser.'
            ),
            'The Vendor must provide the Purchaser with cheque details no less than two Business Days prior to Settlement.',
            'The Purchaser will pay the cost of the first two bank cheques required by the Vendor at Settlement. The Vendor will pay the cost of any additional bank cheques required by it.',
            'If, for any reason a cheque tendered at Settlement is not paid on presentation, the Purchaser must \nre-convey the Property to the Vendor, and any unpaid amount of any cheque will be a charge on the Property in favour of the Vendor.'
          )
        ),
        clause('Default by Purchaser',
          ol(
            stack(
              'Payment of Deposit',
              'Notwithstanding any other provision of this Contract, in the event the Purchaser fails to pay all or any part of the Deposit in accordance with General Condition 4.1, the Vendor can immediately terminate this Contract on serving notice on the Purchaser.'
            ),
            'Prior to Settlement',
            ol(
              'In the event the Purchaser defaults in complying with any of its obligations under this Contract prior to Settlement, the Vendor may serve notice on the Purchaser requiring the Purchaser to remedy the default within no less than five Business Days of service of the notice.',
              'If the Purchaser fails to comply with the notice, the Vendor may terminate this Contract on serving further notice on the Purchaser.',
              'The Vendor may serve more than one notice under this General Condition 16.2.'
            ),
            'At Settlement',
            ol(
              stack(
                'In the event:',
                ol(
                  'the Purchaser defaults in complying with any of its obligations under this Contract to settle on the Settlement Date; and',
                  'the default continues for a period of three Business Days after the Settlement Date,'
                ),
                'the Vendor may serve notice on the Purchaser requiring the Purchaser to remedy the default and settle at a time and date appointed in the notice being no less than three Business Days after the service of the notice.'

              ),
              'If the Purchaser fails to comply with the notice, the Vendor may terminate this Contract on serving further notice on the Purchaser.',
              'The Vendor may serve more than one notice under this General Condition 16.3.'
            ),
            'Remedies of Vendor',
            ol(
              'If this Contract is terminated by the Vendor under this General Condition 16, the Vendor is entitled to retain the Deposit (refer to General Condition 18) and either:',
              ol(
                'retain the Property and sue the Purchaser for damages; or',
                're-sell the Property and:',
                ol(
                  'claim from the Purchaser any deficiency in the re-sale of the Property from the original Purchase Price, including any expenses arising from the re-sale and any losses and expenses incurred by the Vendor resulting from the Purchaser’s default, after taking into consideration any Deposit paid to the Vendor; and',
                  'retain any surplus proceeds arising from the re-sale.'
                )
              ),
              `If Settlement occurs after the Settlement Date at Item 6 or any other date subsequently agreed in writing (${quote(bold('Agreed Settlement Date'))}), to the extent the delay in Settlement is not because of the Vendor’s default in complying with any obligation of the Vendor under this Contract, then, unless otherwise agreed in writing between the parties:`,
              ol(
                'Income is adjusted to midnight the day before Settlement actually occurs;',
                'Outgoings remain adjusted to midnight the day before the Agreed Settlement Date; and',
                'if demanded by the Vendor, the Purchaser must pay at Settlement interest on the Purchase Price at the Default Rate calculated daily for the period from and including the Agreed Settlement Date to midnight the day before Settlement occurs.'
              ),
              'The parties agree that:',
              ol(
                'any interest payable under General Condition 16.4(b)(iii) is a reasonable estimate of the Vendor’s loss or damage for use of the money, and for the purposes of General Condition 5, will be added to and included in the definition of Purchase Price; and',
                'a statement from the Vendor’s Representative calculating the interest will be prima facie evidence of the rate and the amount of interest payable.'
              )
            ),
            'The Vendor’s rights under this General Condition 16 are without prejudice to the Vendor’s other rights and entitlements under this Contract or otherwise at law.'
          )
        ),
        clause('Default by Vendor',
          ol(
            stack(
              'In the event:',
              ol(
                'the Vendor defaults in complying with any of its obligations under this Contract; and',
                'the default continues for a period of three Business Days,'
              ),
              'the Purchaser may serve notice on the Vendor requiring the Vendor to remedy the default within no less than three Business Days from service of the notice.'
            ),
            'If the Vendor fails to comply with the notice, the Purchaser may terminate this Contract on serving further notice on the Vendor.',
            'The Purchaser may serve more than one notice under this General Condition 17.',
            'The Purchaser’s rights under this General Condition 17 are without prejudice to the Purchaser’s other rights and entitlements under this Contract or otherwise at law.'
          )
        ),
        clause('Release of Deposit to Vendor',
          ol(
            'If the Vendor is entitled to the Deposit pursuant to General Condition 16.4(a) , then, unless the parties agree otherwise, before the Stakeholder can release the Deposit to the Vendor, the Vendor must serve:',
            ol(
              `a notice on the Purchaser notifying the Purchaser that the Vendor intends to instruct the Stakeholder to release the Deposit to the Vendor, including on what grounds the Deposit is payable to the Vendor (${quote(bold('Release Notice'))}); and`,
              'a copy of the Release Notice on the Stakeholder as soon as reasonably practicable after serving it on the Purchaser.'
            ),
            'If the Purchaser:',
            ol(
              `objects to the Release Notice, the Purchaser must serve on both the Vendor and the Stakeholder its objection, including the reasons why it objects to the release of the Deposit to the Vendor (${quote(bold('Purchaser’s Objection'))}) within five Business Days of receipt of the Release Notice; or`,
              'does not serve a Purchaser’s Objection on both the Vendor and the Stakeholder within five Business Days of receipt of the Release Notice, the Stakeholder is irrevocably authorised and directed by the parties to release the Deposit to the Vendor.'
            ),
            'If the Purchaser serves a Purchaser’s Objection in accordance with General Condition 18.2(a) and the dispute between the parties is not resolved and communicated to the Stakeholder within 10 Business Days of receipt of the Purchaser’s Objection, the Stakeholder may pay the monies into court.',
            'To the extent that the Stakeholder has complied with its obligations under this General Condition, the parties agree to release and indemnify the Stakeholder from any loss or damage a party incurs arising out of the Stakeholder’s acts or omission to act.'
          )
        ),
        clause('Notices, service and interest for late Settlement',
          ol(
            `A party is entitled to recover up to $550.00 (including GST) from the other party for the cost of the preparation and service of each notice duly served by it or on its behalf under General Conditions 16 or 17 (${quote(bold('Default Cost'))}).`,
            'The Default Cost must be paid by the relevant Party on the earlier of Settlement or on termination of this Contract, and for the purposes of General Condition 5, will adjust and be included in the definition of Purchase Price.',
            'A notice required to be given or served by a party under this Contract:',
            ol(
              'must be signed by that party or the Representative for that party; and',
              'is sufficiently served on a party by:',
              ol(
                'delivering it to the party’s address specified in this Contract;',
                'posting it to the party’s address specified in this Contract via an Australia Post service offering that provides tracking on the envelope; or',
                'sending it by email to the party’s email address specified in this Contract.'
              )
            ),
            'Any notice served pursuant to this Contract is taken to be received:',
            ol(
              'in the case of delivery, on that day, unless delivered after 5:00pm or on a day other than a Business Day, then on the next Business Day;',
              'in the case of post, on the day Australia Post says it was delivered, unless delivered after 5:00pm or on a day other than a Business Day, then on the next Business Day; and',
              'in the case of email, immediately upon sending the email if sent before 5:00pm on a Business Day, otherwise on the next Business Day.'
            ),
            'A party may update its address for service of notice by further notice to the other party.',
            'Where a party comprises more than one Person, service on one of those Persons will, for all purposes, be deemed service on the party.'
          )
        ),
        clause('GST general',
          ol(
            'General',
            ol(
              'Words and phrases used in this General Condition and in the GST Conditions of Sale have the same meaning as in the GST Act, unless the context otherwise requires.',
              'If the sale of the Property constitutes a mixed supply (for example, the sale is input taxed in part) then the Vendor must reasonably determine the consideration for the sale of each part, and in relation to each part, these General Conditions will apply as if the Property being supplied is that part only.'
            ),
            stack(
              'Vendor not registered for GST',
              'If the GST Conditions of Sale provide that the Vendor is not registered or required to be registered for GST, the Vendor warrants this to be true.'
            ),
            stack(
              'Sale is input taxed',
              'If the GST Conditions of Sale provide that the sale of the Property is the supply of residential premises that is input taxed:',
              ol(
                `the Vendor warrants the residential premises are not ${quote('commercial residential premises')} or ${quote('new residential premises')}; and`,
                'the Purchaser warrants that the Property is to be used predominantly for residential accommodation.'
              )
            ),
            'Going concern',
            ol(
              'If the GST Conditions of Sale provide that the sale of the Property is the supply of a going concern:',
              ol(
                'the Purchaser warrants that it is registered or required to be registered for GST, and will (if requested) provide evidence to that effect to the reasonable satisfaction of the Vendor at Settlement;',
                'the Vendor warrants that it will carry on the enterprise to which the sale of the Property relates until Settlement; and',
                'the Vendor and Purchaser agree to treat the sale of the Property as the supply of a going concern.'
              ),
              'For the avoidance of doubt, if the Purchaser assigns this Contract at any time prior to Settlement, the assignee, as a condition of the assignment, is bound by the warranties and agreement made by the Purchaser in this General Condition.'
            ),
            stack(
              'Margin scheme',
              'If the GST Conditions of Sale provide that the sale of the Property is a taxable supply and the margin scheme will apply to the sale of the Property:',
              ol(
                'the Vendor warrants that the Property was not acquired through a taxable supply in which the margin scheme did not apply;',
                'if applicable, the Vendor must obtain, at the Vendor’s cost, a valuation of the Property which complies with section 75-10(3) of the GST Act, and provide that valuation to the Purchaser not less than five Business Days prior to Settlement;',
                `if the parties agree that the margin will be included in the ${quote('GST payable')} at Item 5 once determined, the Purchaser must pay to the Vendor an additional amount on account of GST equal to 10% of the margin at Settlement, and the parties agree that this Contract is binding on both parties where it is fully executed before the ${quote('GST payable')} amount is determined; and`,
                'the Vendor must choose to apply the margin scheme to the sale of the Property in completing its Business Activity Statement.'
              )
            ),
            stack(
              'Taxable supply',
              'If the GST Conditions of Sale provide that the sale of the Property is a taxable supply and the margin scheme does not apply to the sale of the Property:',
              ol(
                'the Purchaser must pay to the Vendor an additional amount on account of GST equal to the consideration for the sale of the Property multiplied by the GST rate applicable; and',
                'the Vendor must provide to the Purchaser a tax invoice in relation to the sale of the Property at Settlement.'
              )
            ),
            'Incorrect treatment',
            ol(
              'If the Vendor identifies before Settlement that an error has been made in the GST Conditions of Sale responses, then:',
              ol(
                `the Vendor must advise the Purchaser in writing of the correct responses in the GST Conditions of Sale, and any resulting changes to the Amount payable, GST payable and Purchase Price (${quote(bold('Vendor’s GST Notice'))});`,
                stack(
                  'in the event that:',
                  ol(
                    'the Purchaser is not registered for GST and the Purchase Price is increased by the Vendor’s GST Notice; or',
                    'the Purchaser is registered for GST and the Amount payable is increased by the Vendor’s GST Notice,'
                  ),
                  `the Purchaser may terminate this Contract immediately by serving notice on the Vendor (${quote(bold('Purchaser’s GST Response'))}) no later than five Business Days from service of the Vendor’s GST Notice (${quote(bold('Response Due Date'))}); and`
                ),
                'if:',
                ol(
                  'the Purchaser is not entitled to, or does not serve a Purchaser’s GST Response on the Vendor by the Response Due Date, this Contract is deemed varied in accordance with the Vendor’s GST Notice; and',
                  'the Vendor’s GST Notice is served on the Purchaser less than five Business Days prior to the Settlement Date, notwithstanding any other term of this Contract, the Settlement Date is deemed varied to the date 10 Business Days after service of the Vendor’s GST Notice.'
                )
              ),
              stack(
                'Subject to General Condition 20.7(a), if:',
                ol(
                  'it was agreed by the parties in the GST Conditions of Sale responses that the sale of the Property was not subject to GST, or was input taxed or GST-free; and',
                  'whether before or after Settlement, GST becomes subsequently payable by the Vendor in relation to this Contract for any reason other than as a consequence of the Vendor’s breach of this General Condition 20,'
                ),
                'the Purchaser must pay to the Vendor on written demand the GST, and any applicable interest, penalties, costs and damages incurred by the Vendor in respect of the GST liability.'
              )
            )
          )
        ),
        clause('GST withholding at Settlement',
          ol(
            'General',
            ol(
              'Words and phrases used in this General Condition which are defined in the Tax Act or the GST Act have the same meaning in this General Condition unless the context otherwise requires.',
              'This General Condition applies unless the sale of the Property is a supply of:',
              ol(
                `${quote('commercial residential premises')}; or`,
                `${quote('potential residential land')} to another entity that is: `,
                ol(
                  'registered for GST; and',
                  'acquires the land for a creditable purpose.'
                )
              ),
              'Where this General Condition applies, the Purchaser will be required to withhold an amount from the Purchase Price and remit it to the ATO in accordance with this General Condition where the Vendor is registered or required to be registered for GST and the Property is:',
              ol(
                `a ${quote('new residential premises')} (other than those created through a substantial renovation); or`,
                `${quote('potential residential land')}.`
              )
            ),
            `If ${doubleQuote('No')} is selected to the question: ${doubleQuote('Is GST withholding at Settlement applicable?')} in the Schedule, then the Vendor gives notice to the Purchaser that the Purchaser will not be required to make a payment under section 14-250 of Schedule 1 to the Tax Act (${quote(bold('Contract Withholding Notice'))}).`,
            `If ${doubleQuote('No')} is not selected to the question: ${doubleQuote('Is GST withholding at Settlement applicable?')} in the Schedule, or if the Contract Withholding Notice at General Condition 21.2 is in error, at least three Business Days before the Relevant Date in General Condition 21.5, the Vendor must provide to the Purchaser a notice which complies in all respects with section 14-255(1) of Schedule 1 to the Tax Act (${quote(bold('Withholding Notice'))}).`,
            'The Vendor may give more than one Withholding Notice to the Purchaser.',
            stack(
              `If the Withholding Notice provides that the Purchaser will be required to make a payment under section 14-250 of Schedule 1 to the Tax Act, the Purchaser must pay to the Commissioner the amount set out in the Withholding Notice (${quote(bold('GST Withholding Amount'))}) on or before:`,
              ol(
                'the day on which it pays the Purchase Price or any equivalent (excluding the Deposit) to the Vendor for the Property;',
                'if the Vendor and Purchaser are associates and no consideration is to be provided, the day on which the Property is supplied to the Purchaser; or',
                'such other date determined by the Commissioner in accordance with section 14-250(5) of Schedule 1 to the Tax Act,'
              ),
              `(${quote(bold('the Relevant Date'))}).`
            ),
            'The GST Withholding Amount is to be withheld from the Vendor’s entitlement to the Purchase Price.',
            'The Purchaser is taken to have complied with its obligations in General Condition 21.5 if:',
            ol(
              'Settlement is conducted through an Electronic Workspace and the GST Withholding Amount is paid to the Commissioner via the Electronic Workspace; or',
              'at Settlement the Purchaser provides the Vendor with a bank cheque made payable to the Commissioner for the GST Withholding Amount payable to the Commissioner.'
            ),
            'If General Condition 21.7(b) applies, the Vendor must provide the bank cheque to the Commissioner as soon as possible following Settlement.',
            stack(
              'The Purchaser must:',
              ol(
                'pay, or ensure payment of, the GST Withholding Amount to the Commissioner in the manner required by the Commissioner; and',
                'promptly provide the Vendor with proof of payment,'
              ),
              'despite any contrary instructions from the Vendor.'
            ),
            'Subject to the Vendor’s compliance with its obligations in this General Condition, the Purchaser is responsible for any penalties or interest payable to the Commissioner on account of late payment of the GST Withholding Amount.'
          )
        ),
        clause('Foreign resident capital gains withholding',
          ol(
            `This General Condition only applies if the Purchaser is required to pay the Commissioner an amount in accordance with sections 14-200 or 14-235 of Schedule 1 to the Tax Act (${quote(bold('FRCG Withholding Amount'))}) because the transaction is not excluded under section 14-215(1) of Schedule 1 to the Tax Act.`,
            stack(
              'Any:',
              ol(
                'clearance certificate; or',
                `document evidencing variation of the FRCG Withholding Amount in accordance with section 14-235 of Schedule 1 to the Tax Act (${quote(bold('Variation'))}),`
              ),
              'must be given to the Purchaser at least three Business Days before the Settlement Date.'
            ),
            'The specified period in the clearance certificate must include the actual date of Settlement.',
            stack(
              'If the Vendor fails to provide the Purchaser with the clearance certificate or a Variation varying the FRCG Withholding Amount to nil as required in accordance with General Condition 22.2, the Purchaser must:',
              ol(
                'pay, or ensure payment of, the FRCG Withholding Amount to the Commissioner in the manner required by the Commissioner; and',
                'promptly provide the Vendor with proof of payment,'
              ),
              'despite any contrary instructions, from the Vendor.'
            ),
            'The Purchaser is taken to have complied with the obligations in General Condition 22.4 if Settlement is conducted through an Electronic Workspace and the FRCG Withholding Amount is paid to the Commissioner via the Electronic Workspace.',
            'Words defined or used in Subdivision 14-D of Schedule 1 to the Tax Act have the same meaning in this General Condition unless the context requires otherwise.'
          ),
        ),
        clause('Release of security interests',
          ol(
            'This General Condition applies if any part of the Property is subject to a security interest to which the PPS Act applies.',
            'Within five Business Days of a written request from the Purchaser, the Vendor must provide the Purchaser with either:',
            ol(
              `the information about the Vendor required for the Purchaser to undertake a Personal Property Securities Register (${quote(bold('PPSR'))}) search in relation to the Vendor; or`,
              'a PPSR search result in relation to the Vendor.'
            ),
            'The Purchaser must advise the Vendor of any security interest registered in the PPSR that the Purchaser reasonably requires to be released as soon as reasonably practicable following the Contract Date and, where time permits, no later than 15 Business Days before the Settlement Date.',
            'In respect of the security interests registered in the PPSR that the Purchaser reasonably requires to be released, the Vendor must ensure that at or before Settlement, the Purchaser receives:',
            ol(
              'a release from the secured party releasing the security interest in respect of the Property;',
              'a statement in writing in accordance with section 275(1)(b) of the PPS Act setting out that the amount or obligation that is secured is nil at the due date for Settlement; or',
              'a written approval or correction in accordance with section 275(1)(c) of the PPS Act indicating that, on the due date for Settlement, the personal property included in this Contract is not or will not be property in which the security interest is granted.'
            ),
            'The Vendor is not obliged to ensure that the Purchaser receives a release, statement, approval or correction in respect of any personal property that is sold in the ordinary course of the Vendor’s business of selling personal property of that kind unless, in the case of goods that may or must be described by a serial number in the PPSR, the Purchaser advises the Vendor as soon as reasonably practicable following the Contract Date and, where time permits, no later than 15 Business Days before the Settlement Date that the goods are to be held as inventory.',
            'The Vendor is not obliged to ensure that the Purchaser receives a release, statement, approval or correction in respect of any personal property that:',
            ol(
              'is not described by serial number in the PPSR;',
              'is predominantly used for personal, domestic or household purposes; and',
              'has a market value of not more than $5,000.00 or, if a greater amount has been prescribed for the purposes of section 47(1) of the PPS Act, not more than that prescribed amount.'
            ),
            'A release for the purposes of General Condition 23.4(a) must be in writing and in a form published by a professional legal body, Law Council of Australia or the Australian Bankers Association or in a form acceptable to the Vendor in its discretion or published under the PPS Act.',
            'In addition to ensuring a release is received under General Condition 23.4(a), the Vendor must ensure that at or before Settlement, the Purchaser receives a written undertaking from a secured party to register a financing change statement to reflect that release if the Property being released includes goods of a kind that are described by serial number in the PPSR.',
            'Words and phrases used in this General Condition which are defined in the PPS Act have the same meaning in this General Condition unless the context requires otherwise.'
          )
        ),
        clause('Registered Encumbrances',
          ol(
            `If the Property is subject to a registered Encumbrance as at the Contract Date (${quote(bold('Existing Encumbrance'))}) that prohibits the Vendor from selling the Property without the Vendor procuring the Purchaser enter a new Encumbrance on the same terms as the Existing Encumbrance, the Purchaser acknowledges and agrees:`,
            ol(
              `at the Purchaser’s cost, to do all things reasonably necessary to execute a new Encumbrance on the terms required by the Existing Encumbrance (${quote(bold('New Encumbrance'))}) and in a form for registration on the Certificate of Title to the Property at least five Business Days prior to Settlement; and`,
              'that the New Encumbrance may be required to be registered on the Certificate of Title of the Property immediately after the Purchaser becomes the registered proprietor of the Property and before any other charges or mortgages are registered on the Certificate of Title to the Property.'
            ),
            'If the Existing Encumbrance does not require registration of a New Encumbrance on the sale of the Property, the Purchaser:',
            ol(
              'acknowledges that the Property will remain subject to the Existing Encumbrance after Settlement; and',
              'agrees to comply with the terms of the Existing Encumbrance and indemnifies the Vendor against any loss or damage incurred by the Vendor arising from the breach by the Purchaser of the terms of the Existing Encumbrance.'
            )
          )
        ),
        clause('Costs and stamp duty',
          ol(
            'Each party will bear its own legal and other costs and expenses in entering into this Contract and Settlement.',
            'The Purchaser will pay:',
            ol(
              'all stamp duty assessed on this Contract and on the transfer to the Purchaser; and',
              'any registration fees charged by Land Services SA in relation to the transfer of the Property.'
            )
          )
        ),
        clause('Time of the essence',
          'Time will be of the essence in this Contract in respect of any obligation under General Conditions 16 or 17, the Special Conditions and the Additional Conditions.',
        ),
        clause('Further assurance and endeavours',
          `The parties must each use all reasonable endeavours to comply with any obligation of this Contract, including to deal with any ${quote('requisition notice')} issued by Land Services SA.`,
        ),
        clause('No merger',
          'The provisions of this Contract continue to subsist after Settlement.',
        ),
        clause('Legal capacity of Purchaser',
          'The Purchaser warrants that:',
          ol(
            'all natural persons included in the description of Purchaser are 18 years of age and are not under any legal disability;',
            'it does not require approval from the Foreign Investment Review Board (or any similar organisation) for the purchase unless otherwise specified; and',
            'if executing as attorney or as a nominee that they are validly appointed and have not received notice of any withdrawal of power.'
          )
        ),
        clause('Vendor finance and exclusion of nominee',
          'If the whole or any part of the Purchase Price is to be financed by the Vendor, then the Purchaser is not entitled to appoint a nominee or assign this Contract or any part of it without the prior written consent of the Vendor.',
        ),
        clause('Counterparts',
          ol(
            'This Contract may be executed in any number of counterparts, and by a party on separate counterparts.',
            'All counterparts taken together constitute one and the same instrument.'
          )
        ),
        clause('Electronic signing and communications',
          'The parties acknowledge and agree to:',
          ol(
            'the signing by any party of this Contract, the Form 1 and any other documents by digital or electronic signatures; and',
            `all communications relating to the sale of the Property (including the service of any notices under the Act and service of the Form 1) by way of electronic means via the party’s email address or mobile phone number specified in this Contract or as otherwise provided to the other party, the other party's Representative or the Agent from time to time, in accordance with the ${formatAct(LegalJurisdiction.SouthAustralia, 'ElectronicCommunicationsAct2000')}.`
          )
        ),
        clause('Inconsistency',
          'If there is any inconsistency within this Contract:',
          ol(
            'the Special Conditions will prevail over the Additional Conditions and the General Conditions; and',
            'the Additional Conditions will prevail over the General Conditions,'
          ),
          'but only to the extent of the inconsistency.',
        ),
        clause('Auction conditions',
          'If the Property was sold at auction, then unless otherwise agreed to by the parties, the Terms and Conditions of Public Auction of Real Property of the Real Estate Institute of South Australia and Society of Auctioneers and Appraisers SA Inc, exhibited prior to the auction will apply to this Contract.',
        ),
        clause('Privacy',
          ol(
            'The parties acknowledge and agree that:',
            ol(
              'the Agent may:',
              ol(
                'use personal information of a party collected from this Contract;',
                'disclose the personal information for general purposes to legal advisors, conveyancers, financial institutions, connection services, body corporate managers, insurers, valuers and the agents of banks and financiers who may provide finance or insurance, their agents, or any other Persons and for all purposes for any valuation databases; and',
                `collect and disclose the personal information to any third-party technology platforms used by the Agent in the conduct of their business, including for marketing and survey purposes or as otherwise allowed under the ${formatAct(LegalJurisdiction.Commonwealth, 'PrivacyAct1988')}; and`
              ),
              'if a party requires access to the personal information, they may contact the Agent at the contact details in this Contract.'
            ),
            'The parties acknowledge and agree that:',
            ol(
              'Greatforms may use personal information of a party collected from this Contract to communicate with a party to this Contract about services they may wish to engage;',
              'it will be in the parties’ discretion whether to engage with any service providers introduced by Greatforms;',
              'all personal information gathered may be used for statistical purposes, however, any such information will be anonymised; and',
              'if a party requires access to the personal information, they may contact Greatforms at privacy@greatforms.com.au.'
            ),
            // No condition currently exists to detect Direct Connect, so we'll just put this here for now.
            (undefined && stack(
              'The parties acknowledge and agree that:',
              ol(
                `${quote(bold('Direct Connect'))} means Direct Connect Australia Pty Ltd which is a moving house and utilities connection service assisting people when moving into a property.`,
                'Direct Connect will collect, use and disclose personal information of a party collected from this Contract for the purposes of contacting a party in relation to utilities and service connections, and for purposes otherwise set out in Direct Connect’s Privacy Policy;',
                'the personal information may be disclosed to third parties that help Direct Connect deliver its services;',
                `Direct Connect’s Privacy Policy explains how it will collect, use, store and disclose personal information, the consequences for a party if Direct Connect does not collect this information, and the way in which you can access and seek correction of your personal information or complain about a breach of the ${formatAct(LegalJurisdiction.Commonwealth, 'PrivacyAct1988')}; and`,
                'if they want access to the Privacy Policy and further information, they may visit https://www.directconnect.com.au/privacypolicy/.'
              )
            ))
          )
        )
      ])
    }
  ].map(node => setNumberingSideEffect(node).node);
}
