import { ContractSettlementType, SaleDetailsType } from '@property-folders/contract';
import {
  drawUnderline,
  fieldFocus, fieldsSpacing, generateCheckboxTextColumns,
  itemSection,
  itemSubsection,
  spaceStackLinesSideEffect
} from '..';
import { Predicate } from '../../../predicate';
import { canonicalisers } from '../../formatting';
import { FieldPlaceholderStyle } from '../standards';
import { contractSettlementDefaultEnables } from '../../../data-and-text/constants';
import { cloneDeep } from 'lodash';

export function settlementSubsection(
  contractSettlement?: ContractSettlementType,
  opts?: {
    isVariation?: boolean,
    isOffer?: boolean,
    isOfferContract?: boolean,
    isTemplateContract?: boolean,
    showTitle?: boolean,
    isSaa?: boolean,
    shortMode?: boolean
  }) {
  const { isOffer, isOfferContract, isTemplateContract, isVariation, showTitle, shortMode } = opts ?? {};
  const sectionItems = [];
  let singleOptionHit = false;
  const restrictInOfferMode = (value?: boolean) => {
    if (!isOffer) return value;
    return singleOptionHit ? undefined : value;
  };
  const updateOptionHit = (value?: boolean) => {
    if (isOffer) return;
    if (singleOptionHit) return;

    singleOptionHit = !!value;
  };

  sectionItems.push({ text: opts?.isSaa ? 'Settlement is preferred to be:' : 'The Settlement Date will be:', ...(showTitle?{ margin: [0,fieldsSpacing,0,0] }:{}) });

  const settlementOpts = cloneDeep(contractSettlement?.enabledModes??{});

  if ((isOffer||isOfferContract||isTemplateContract) && !contractSettlement?.otherText) {
    delete settlementOpts.other;
  }

  const optionVisibility = contractSettlement?.enabledModes && Object.values(settlementOpts).some(a=>a) ? settlementOpts : contractSettlementDefaultEnables;

  const displayKeys = [
    'date',
    'contract',
    'condition',
    'other'
  ].filter(key => optionVisibility[key]);

  if (opts?.isSaa) displayKeys.push('unset');

  const lastDisplay = displayKeys[displayKeys.length-1];

  if (optionVisibility.date) {
    const dateLineEnd = lastDisplay === 'date' ? '.' : '; or';
    sectionItems.push(generateCheckboxTextColumns(
      contractSettlement?.onDate && contractSettlement?.date
        ? `on ${contractSettlement.date}${dateLineEnd}`
        : { text: [
          'on ',
          drawUnderline(FieldPlaceholderStyle.Location),
          dateLineEnd
        ].filter(Predicate.isTruthy) },
      restrictInOfferMode(contractSettlement?.onDate)
    ) );
    updateOptionHit(contractSettlement?.onDate);
  }

  if (optionVisibility.contract) {
    const onContractDaysText = ' days after the Contract Date';
    const onContractDaysNumberText = contractSettlement?.onContractDays?.toString().replace(/[^\d]/g, '');
    const contractLineEnd = lastDisplay === 'contract' ? '.' : '; or';
    sectionItems.push(generateCheckboxTextColumns(
      contractSettlement?.onContract && onContractDaysNumberText
        ? `${onContractDaysNumberText}${onContractDaysText}${contractLineEnd}`
        : { text: [drawUnderline(FieldPlaceholderStyle.Amount), onContractDaysText, contractLineEnd] },
      restrictInOfferMode(contractSettlement?.onContract)
    ) );
    updateOptionHit(contractSettlement?.onContract);
  }

  if (optionVisibility.condition) {
    const conditionLineEnd = lastDisplay === 'condition' ? '.' : '; or';
    const onConditionDaysText = ' Business Days after the date on which all the Special Conditions and any conditional Additional Conditions are satisfied or waived';
    sectionItems.push(generateCheckboxTextColumns(
      contractSettlement?.onCondition && contractSettlement?.onConditionDays
        ? `${canonicalisers.days(contractSettlement.onConditionDays).canonical}${onConditionDaysText}${conditionLineEnd}`
        : { text: [drawUnderline(FieldPlaceholderStyle.Amount), onConditionDaysText, conditionLineEnd] },
      restrictInOfferMode(contractSettlement?.onCondition)
    ) );
    updateOptionHit(contractSettlement?.onCondition);
  }

  if (optionVisibility.other) {
    const otherLineEnd = lastDisplay === 'other' ? '.' : '; or';
    const onOther = 'other: ';
    sectionItems.push(generateCheckboxTextColumns(
      contractSettlement?.other && contractSettlement?.otherText
        ? `${onOther}${contractSettlement?.otherText}${otherLineEnd}`
        : { text: [onOther, drawUnderline(FieldPlaceholderStyle.OtherLong), otherLineEnd] },
      restrictInOfferMode(contractSettlement?.other)
    ) );
    updateOptionHit(contractSettlement?.other);
  }

  if (opts?.isSaa) {
    sectionItems.push(generateCheckboxTextColumns(
      { text: 'No set preference.' },
      contractSettlement?.noPref
    ) );
  }

  if (displayKeys.length > 1 && !shortMode) {
    sectionItems.push({ margin: [0,fieldsSpacing, 0, 0], text: 'If more than one option is selected above, the Settlement Date will be on the last to occur of the selected options.' });
  }

  return itemSubsection({
    subsectionTitle: showTitle ? 'Settlement' : undefined, titleLineContent: undefined, subsectionContent: spaceStackLinesSideEffect(sectionItems),
    isVariation
  });
}

export function contractSettlementSection(
  itemNo: number,
  contractSettlement: ContractSettlementType | undefined,
  sale?: SaleDetailsType,
  isVariation?: boolean,
  isOffer?: boolean,
  opts?: {isTemplateContract?: boolean, isOfferContract?: boolean}
) {
  const { isTemplateContract, isOfferContract } = opts??{};
  const sectionItems = [];
  sectionItems.push(settlementSubsection(contractSettlement, { isVariation, isOffer, isTemplateContract, isOfferContract }));
  return itemSection({
    itemNo: itemNo, itemTitleParam: 'Settlement'.toUpperCase(), bookmark: ['bookmark_contractSettlement', 'subsection-contract-settlement', ...fieldFocus('contractSettlement')], stackContent: spaceStackLinesSideEffect(sectionItems),
    isVariation
  });
}
