import { ContractSpecialType } from '@property-folders/contract';
import { canonicalisers, longMonthFormatter, numberOrdinal } from '../../formatting';
import { Predicate } from '../../../predicate';
import {
  documentSubTitle,
  drawUnderlineBetter,
  fieldFocus,
  fieldFocusMap, fullWidthLineIfEmpty, generateCheckboxText, generateCheckboxTextColumns,
  insertIf,
  itemSection,
  itemSubsection,
  lineIfEmpty,
  spaceStackLinesSideEffect
} from '..';
import { formatBI } from '../formatBI';
import { SaleAddress, SaleTitle } from '@property-folders/contract';
import { postSymbolForceSpacer } from '../formatters/postSymbolForceSpacer';
import { FieldPlaceholderStyle } from '../standards';
import { formatAct, quote, ol } from '../formatters/clauses';

interface ExtraAttributes {
  decoration?: string,
  decorationStyle?: string
}

const olLowerAlpha = {
  type: 'lower-alpha',
  separator: ['(', ')' + postSymbolForceSpacer]
};
const olLowerRoman = {
  type: 'lower-roman',
  separator: ['(', ')' + postSymbolForceSpacer]
};

function text (text: string) {
  return { text };
}

export function offerFinanceSubsection(
  contractSpecial: ContractSpecialType | undefined,
  strikeThrough?: boolean
) {
  if (contractSpecial?.hideSection || contractSpecial?.hideFinance) {
    return [];
  }
  const financeAmount = canonicalisers.aud(contractSpecial?.financeAmount || '');
  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect([
      generateCheckboxTextColumns('Subject to finance', contractSpecial?.financeRequired),
      spaceStackLinesSideEffect([
        { ul: spaceStackLinesSideEffect([
          { text: [
            'Loan amount: ',
            contractSpecial?.financeRequired && financeAmount.valid
              ? financeAmount.display
              : drawUnderlineBetter(FieldPlaceholderStyle.Price)
          ] },
          { text: [
            'Latest date for approval: ',
            contractSpecial?.financeRequired && contractSpecial.financeDeadline
              ? contractSpecial.financeDeadline
              : drawUnderlineBetter(FieldPlaceholderStyle.Date)
          ] },
          { text: [
            'Maximum interest rate acceptable: ',
            contractSpecial?.financeRequired && contractSpecial.financeInterestRate
              ? contractSpecial.financeInterestRate
              : drawUnderlineBetter(FieldPlaceholderStyle.Percentage)
          ] }
        ]) }
      ], 3)
    ]),
    unbreakable:
    undefined,
    bookmark: ['bookmark_offerFinance'],
    otherStackAttributes: strikeThrough ? { decoration: 'lineThrough' } : undefined
  });
}

function buildSecuredPropertyOl(addresses: string[]) {
  return addresses.map((addr, index, arr) => {
    let buildString = addr;

    if (index >= arr.length-1) {
      buildString = buildString+',';
      return buildString;
    }
    if (index === arr.length-2) {
      buildString = buildString+'; and';
      return buildString;
    }
    return buildString+';';
  });
}

export function financeSubsection(
  contractSpecial: ContractSpecialType | undefined,
  isOffer: boolean | undefined,
  property?: {addresses?: SaleAddress[], titles?: SaleTitle[]},
  opts?: {
    templateAuctionMode?: boolean
    templateContractMode?: boolean
  }
) {
  if (contractSpecial?.hideSection || contractSpecial?.hideFinance) {
    return [];
  }
  const { templateAuctionMode = false, templateContractMode = false } = opts??{};
  const bookmark = ['bookmark_contractSpecialFinance', 'subsection-purchaser-finance', ...fieldFocusMap({ contractSpecial: [
    'financeRequired',
    'financeDeadline',
    'financeDeadlineTime',
    'financeAmount',
    'financeInterestRate',
    'financeUseSpecifiedRate',
    'financeUseSpecifiedLender',
    'financeLender'
  ] })];
  const noFinance = Predicate.boolFalse(contractSpecial?.financeRequired) || (templateContractMode && Predicate.boolFalse(contractSpecial?.financePermitted));
  const noValues = noFinance || templateContractMode || !contractSpecial?.financeRequired;
  const subsectionItems = [];
  const extraAttrs: ExtraAttributes = {};

  if (noFinance && isOffer) {
    return itemSubsection({
      subsectionTitle: undefined,
      titleLineContent: undefined,
      subsectionContent: spaceStackLinesSideEffect([{
        separator: ['SC\u00A0', ''],
        start: 1,
        ol: spaceStackLinesSideEffect([
          { text: 'FINANCE - Not applicable', bold: true }
        ])
      }]),
      unbreakable: undefined,
      bookmark: bookmark
    });
  }

  if (noFinance) {
    extraAttrs.decoration = 'lineThrough';
    extraAttrs.decorationStyle = 'solid';
  }

  // Use these to adjust line height so that the special condition does not break onto the following page, leaving a hanging
  // special conditions headline
  const cCheckboxes = noValues||contractSpecial?.financeUseSpecifiedRate == null;
  const dCheckboxes = noValues||contractSpecial?.financeUseSpecifiedLender == null;
  const dateCheckboxes = noValues||!(contractSpecial?.financeDeadlineMode === 'date'||contractSpecial?.financeDeadlineMode === 'daysAfter');

  subsectionItems.push({
    separator: ['SC\u00A0', ''],
    start: 1,
    lineHeight: 1.2,
    ol: spaceStackLinesSideEffect([
      { text: 'FINANCE', bold: true },
      {
        separator: ['SC\u00A01.', ''],
        start: 1,
        ol: spaceStackLinesSideEffect([
          {
            stack: [
              'Settlement under this Contract is conditional on the Purchaser obtaining and notifying the Vendor of Approval for a loan:',
              {
                ...olLowerAlpha,
                ol: [
                  {
                    stack: [
                      { text: 'on or before 5:00pm (select one option only):' },
                      { ...olLowerRoman,
                        ol: [
                          {
                            text: [
                              ...insertIf(dateCheckboxes, { text: '☐', style: 'checkbox', font: 'DejaVu', fontSize: 13, baselineOffset: 1.5 }, { text: '   ' }),
                              { text: [{ text: 'on ' }, lineIfEmpty(noValues || contractSpecial?.financeDeadlineMode !== 'date' ? null : contractSpecial?.financeDeadline, FieldPlaceholderStyle.Date), { text: '; or' }] }
                            ],
                            ...(!noValues&&contractSpecial?.financeDeadlineMode === 'daysAfter' ? { decoration: 'lineThrough' } : {}),
                            ...(dateCheckboxes ? { lineHeight: 1.1 } : {})
                          },
                          {
                            text: [
                              ...insertIf(dateCheckboxes, { text: '☐', style: 'checkbox', font: 'DejaVu', fontSize: 13, baselineOffset: 1.5 }, { text: '   ' }),
                              { text: [lineIfEmpty(noValues || contractSpecial?.financeDeadlineMode !== 'daysAfter' ? null : contractSpecial?.financeDeadlineDaysAfter, FieldPlaceholderStyle.Amount), { text: ' days after the Contract Date;' }] }
                            ],
                            ...(!noValues&&contractSpecial?.financeDeadlineMode === 'date' ? { decoration: 'lineThrough' } : {}),
                            ...(dateCheckboxes ? { lineHeight: 1.1 } : {})
                          }
                        ]
                      },
                      formatBI(' (‘*-*Latest Date for Approval*-*’);')
                    ]
                  },
                  {
                    text: [
                      'for ',
                      lineIfEmpty(noValues ? null : contractSpecial?.financeAmount, FieldPlaceholderStyle.Price),
                      ', or such lesser amount the Purchaser accepts;'
                    ]
                  },
                  { stack: [
                    { text: 'with an interest rate no greater than (select one option only):' },
                    { ...olLowerRoman,
                      ol: [
                        {
                          text: [
                            ...insertIf(cCheckboxes, { text: '☐', style: 'checkbox', font: 'DejaVu', fontSize: 13, baselineOffset: 1.5 }, { text: '   ' }),
                            lineIfEmpty(noValues || !contractSpecial?.financeUseSpecifiedRate ? null : contractSpecial?.financeInterestRate, FieldPlaceholderStyle.Percentage), '; or'
                          ],
                          ...(!noValues&&contractSpecial?.financeUseSpecifiedRate === false ? { decoration: 'lineThrough' } : {}),
                          ...(cCheckboxes ? { lineHeight: 1.1 } : {})
                        },
                        { text: [
                          ...insertIf(cCheckboxes, { text: '☐', style: 'checkbox', font: 'DejaVu', fontSize: 13, baselineOffset: 1.5 }, { text: '   ' }),
                          'Market Rate;'
                        ],
                        ...(!noValues&&contractSpecial?.financeUseSpecifiedRate === true ? { decoration: 'lineThrough' } : {}),
                        ...(cCheckboxes ? { lineHeight: 1.1 } : {})
                        }
                      ]
                    }
                  ] },
                  { stack: [
                    { text: 'from the following lender (select one option only):' },
                    { ...olLowerRoman,
                      ol: [
                        { text: [
                          ...insertIf(dCheckboxes, { text: '☐', style: 'checkbox', font: 'DejaVu', fontSize: 13, baselineOffset: 1.5 }, { text: '   ' }),
                          lineIfEmpty(noValues || !contractSpecial?.financeUseSpecifiedLender ? null : contractSpecial?.financeLender, FieldPlaceholderStyle.OtherLong), '; or'
                        ],
                        ...(!noValues&&contractSpecial?.financeUseSpecifiedLender === false ? { decoration: 'lineThrough' } : {}),
                        ...(dCheckboxes ? { lineHeight: 1.1 } : {})
                        },
                        { text: [
                          ...insertIf(dCheckboxes, { text: '☐', style: 'checkbox', font: 'DejaVu', fontSize: 13, baselineOffset: 1.5 }, { text: '   ' }),
                          'if (i) above not completed, then any Lender.'
                        ],
                        ...(!noValues&&contractSpecial?.financeUseSpecifiedLender === true ? { decoration: 'lineThrough' } : {}),
                        ...(dCheckboxes ? { lineHeight: 1.1 } : {})
                        }
                      ]
                    }
                  ] }
                ]
              }

            ]
          },
          {
            stack: [
              'The Purchaser must use its best endeavours to satisfy SC 1.1, which will include:',
              {
                ...olLowerAlpha,
                ol: [
                  'promptly applying for the Approval from the Lender, whether direct to the Lender or via a loan broker; ',
                  'diligently pursuing the Approval, supplying all things reasonably required by the Lender for the purpose of the Approval, and agreeing to the Lender\'s standard terms and security requirements for comparable loans; and',
                  'informing the Vendor regarding the progress of the Approval when reasonably requested by the Vendor or the Vendor’s Representative.'
                ]
              }
            ]
          },
          text('The Purchaser must notify the Vendor promptly after the Approval has been obtained.'),
          {
            stack: [
              text('Upon notification of the Approval to the Vendor, this Special Condition will be satisfied notwithstanding that:'),
              { ...olLowerAlpha,
                ol: [
                  formatBI('the Approval may be conditional; and'),
                  formatBI('the Lender may subsequently withdraw the Approval.')
                ]
              }
            ]
          },
          text('The Purchaser may waive this Special Condition by serving notice of its waiver on the Vendor.'),
          text('In the event that this Special Condition is not satisfied or waived by the Latest Date for Approval, either party, and in the case of the Purchaser only where it has complied with SC 1.2, can immediately terminate this Contract by serving notice on the other party.'),
          {
            stack: [
              'In the event of termination of this Contract:',
              {
                ...olLowerAlpha,
                ol: [
                  'by a party pursuant to SC 1.6, all monies paid by or on behalf of the Purchaser must be repaid to the Purchaser; or',
                  'by the Vendor pursuant to General Condition 16.2 as a result of the Purchaser failing to comply with SC 1.2, the Vendor is entitled to the Deposit without limitation to its other rights under this Contract.'
                ]
              }
            ]
          },
          { text: [
            'In this Special Condition:\n',
            formatBI(`${quote('*-*Approval*-*')} means approval in writing for a loan on the terms set out at SC 1.1 and otherwise on such terms and conditions that are acceptable to the Purchaser to assist in purchasing the Property;\n`),
            formatBI(`${quote('*-*Lender*-*')} means the lender specified at SC 1.1(d)(i), or if no lender or a loan broker is specified at SC 1.1(d)(i), then any bank, credit union or building society licensed to provide standard home loans under the ${formatAct('Cth', 'NationalConsumerCreditProtection2009')};\n`),
            formatBI(`${quote('*-*Market Rate*-*')} means the standard variable home loan interest rate of the Lender for an owner-occupied loan on the amount to be borrowed set out at SC 1.1(b), secured against the Property, with a 30-year principal and interest term, at the time the loan application is made by the Purchaser to the Lender.`)
          ] }
        ])
      }
    ]),
    ...extraAttrs
  });

  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect(subsectionItems),
    unbreakable:
    undefined,
    bookmark: bookmark,
    ...(templateAuctionMode ? { otherStackAttributes: { decoration: 'lineThrough' } } : undefined)
  });
}

export function offerPurchaserSaleSubsection(
  contractSpecial: ContractSpecialType | undefined,
  strikeThrough: boolean
) {
  if (contractSpecial?.hideSection || contractSpecial?.hideSaleRequired) {
    return [];
  }
  const sDate = contractSpecial?.purchaserSaleRequired
  && contractSpecial?.saleSettleAlsoRequired
  && contractSpecial?.purchaserSaleSettlementDeadline
    ? new Date(contractSpecial?.purchaserSaleSettlementDeadline)
    : undefined;

  const listItems = [
    { text: [
      'Sale property address: ',
      lineIfEmpty(contractSpecial?.purchaserSaleRequired && contractSpecial?.salePropertySingleLine, FieldPlaceholderStyle.Location)
    ] },
    { columns: [
      { text: 'Have you already entered into a Contract to sell the property?' },
      { width: 100, margin: [0, -6, 0, 0], text: [
        ...generateCheckboxText('Yes', contractSpecial?.purchaserSaleRequired && contractSpecial?.saleSettleAlsoRequired),
        ' ',
        ...generateCheckboxText('No', contractSpecial?.purchaserSaleRequired && Predicate.boolFalse(contractSpecial?.saleSettleAlsoRequired))
      ] }
    ] }
  ];

  const showHasContractBeenEnteredInto = contractSpecial?.purchaserSaleRequired == null || contractSpecial?.saleSettleAlsoRequired === undefined || (contractSpecial?.purchaserSaleRequired && contractSpecial?.saleSettleAlsoRequired);
  const contractDate = contractSpecial?.purchaserSaleRequired && contractSpecial?.purchaserSaleContractDeadline ? new Date(contractSpecial.purchaserSaleContractDeadline) : undefined;
  listItems.push({ text: [
    contractSpecial?.saleSettleAlsoRequired ? 'Date of contract entered into: on ' : 'Deadline for settling on contract: ',
    dateFormatterPdf(contractDate)
  ] });
  if (showHasContractBeenEnteredInto) {
    listItems.push({ text: [
      'Date of settlement under your sale Contract: on ',
      dateFormatterPdf(sDate)
    ] });
  } else {
    const dsDate = contractSpecial?.purchaserSaleRequired && contractSpecial?.purchaserSaleSettlementDeadline ? new Date(contractSpecial?.purchaserSaleSettlementDeadline) : undefined;
    listItems.push({ text: [
      'Deadline for settling on contract: ',
      dateFormatterPdf(dsDate)
    ] });
  }

  // desire is to always include this unless they've explicitly selected Yes
  if (showHasContractBeenEnteredInto) {
    listItems.push({ columns: [
      { text: 'Is your sale Contract unconditional?' },
      { width: 100, margin: [0, -6, 0, 0], text: [
        ...generateCheckboxText('Yes', contractSpecial?.purchaserSaleRequired && contractSpecial?.saleSettleAlsoRequired && contractSpecial?.saleSettleUnconditional),
        ' ',
        ...generateCheckboxText('No', contractSpecial?.purchaserSaleRequired && contractSpecial?.saleSettleAlsoRequired && Predicate.boolFalse(contractSpecial?.saleSettleUnconditional))
      ] }
    ] });

  }

  // desire is to always include this unless they've explicitly selected No
  if (contractSpecial?.purchaserSaleRequired == null || contractSpecial?.saleSettleAlsoRequired === undefined || (contractSpecial?.purchaserSaleRequired && Predicate.boolFalse(contractSpecial?.saleSettleAlsoRequired))) {
    listItems.push({ columns: [
      { text: 'Is your sale property currently listed for sale?' },
      { width: 100, margin: [0, -6, 0, 0], text: [
        ...generateCheckboxText('Yes', contractSpecial?.purchaserSaleRequired && Predicate.boolFalse(contractSpecial?.saleSettleAlsoRequired) && contractSpecial.salePropertyListedForSale),
        ' ',
        ...generateCheckboxText('No', contractSpecial?.purchaserSaleRequired && Predicate.boolFalse(contractSpecial?.saleSettleAlsoRequired) && Predicate.boolFalse(contractSpecial.salePropertyListedForSale))
      ] }
    ] });
    listItems.push({ text: [
      'Minimum price you need to sell for, to be able to purchase this property: ',
      lineIfEmpty(contractSpecial?.purchaserSaleRequired && contractSpecial?.purchaserSaleMinimumPrice, FieldPlaceholderStyle.Price)
    ] });
  }

  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect([
      generateCheckboxTextColumns('Subject to Sale and/or Settlement of my/our property', contractSpecial?.purchaserSaleRequired),
      spaceStackLinesSideEffect([
        { ul: spaceStackLinesSideEffect(listItems) }
      ], 3)
    ]),
    unbreakable:
    undefined,
    bookmark: ['bookmark_offerPurchaserSale'],
    otherStackAttributes: strikeThrough ? { decoration: 'lineThrough' } : undefined
  });
}

export function dateFormatterPdf(date?: Date) {
  return lineIfEmpty(date ? `${numberOrdinal(date.getDate()) ?? ''} ${longMonthFormatter(date)} ${date.getFullYear()}` : undefined, FieldPlaceholderStyle.Date);
}

export function purchaserSaleSubsection(
  contractSpecial: ContractSpecialType | undefined,
  opts?: {
    isOffer?: boolean,
    templateAuctionMode?: boolean
    templateContractMode?: boolean
  }
) {
  if (contractSpecial?.hideSaleRequired || contractSpecial?.hideSection) {
    return [];
  }
  const { templateAuctionMode = false, templateContractMode = false, isOffer = false } = opts??{};
  const bookmark = ['bookmark_contractSpecialPurchaserSale', 'subsection-purchaser-sale', ...fieldFocusMap({ contractSpecial: [
    'purchaserSaleRequired',
    'saleSettleAlsoRequired',
    'salePropertySingleLine',
    'purchaserSaleContractDeadline',
    'purchaserSaleSettlementDeadline',
    'purchaserSaleMinimumPrice'
  ] })];

  const noPurchaserSale = Predicate.boolFalse(contractSpecial?.purchaserSaleRequired)  || (templateContractMode && Predicate.boolFalse(contractSpecial?.purchaserSalePermitted));

  const extraAttrs: ExtraAttributes = {};
  const subsectionItems = [];
  const noValues = !(!templateContractMode && !noPurchaserSale && contractSpecial?.purchaserSaleRequired);
  const clauseIsDecided = !noValues && !templateContractMode && !noPurchaserSale && contractSpecial?.purchaserSaleRequired && !Predicate.isNullish(contractSpecial?.saleSettleAlsoRequired);

  const cDate = !noValues && contractSpecial?.purchaserSaleContractDeadline ? new Date(contractSpecial?.purchaserSaleContractDeadline) : undefined;
  const sDate = !noValues && contractSpecial?.purchaserSaleSettlementDeadline ? new Date(contractSpecial?.purchaserSaleSettlementDeadline) : undefined;
  const settleUnconditionDate = !noValues && contractSpecial?.saleSettleUnconditionalDeadline ? new Date(contractSpecial?.saleSettleUnconditionalDeadline) : undefined;

  if (noPurchaserSale && isOffer) {
    return itemSubsection({
      subsectionTitle: undefined,
      titleLineContent: undefined,
      subsectionContent: spaceStackLinesSideEffect([{
        separator: ['SC\u00A0', ''],
        start: 2,
        ol: spaceStackLinesSideEffect([
          { text: 'SALE OF THE PURCHASER’S PROPERTY - Not applicable', bold: true }
        ])
      }]),
      unbreakable: undefined,
      bookmark: bookmark });
  }

  if (noPurchaserSale) {
    extraAttrs.decoration = 'lineThrough';
    extraAttrs.decorationStyle = 'solid';
  }

  const hideSaleNSettlement = clauseIsDecided && contractSpecial?.saleSettleAlsoRequired;
  const hideSettlement = clauseIsDecided && Predicate.boolFalse(contractSpecial?.saleSettleAlsoRequired);
  subsectionItems.push({
    separator: ['SC\u00A0', ''],
    start: 2,
    lineHeight: 1.2,
    ol: spaceStackLinesSideEffect([
      { text: [{ text: 'SALE OF THE PURCHASER’S PROPERTY', bold: true }] },
      {
        separator: ['SC\u00A02.', ''],
        start: 1,
        ol: spaceStackLinesSideEffect([
          {
            stack: [
              !clauseIsDecided && formatBI('(/-/* select the applicable option/-/)'),
              !clauseIsDecided && formatBI('\n'),
              (!hideSaleNSettlement && {
                stack: [
                  { text: 'Sale and Settlement', ...(clauseIsDecided ? {} : { style: 'boldEmphasis' }) },
                  {
                    text: [
                      ...insertIf(!clauseIsDecided, { text: '☐', style: 'checkbox', font: 'DejaVu', fontSize: 13, baselineOffset: 1.5 }),
                      { text: '  Settlement under this Contract is conditional on the Purchaser:' }
                    ],
                    margin: [clauseIsDecided?0:-17,4,0,0]
                  },
                  {
                    ...olLowerAlpha,
                    ol: [
                      {
                        stack: [
                          {
                            text: [
                              'entering into a contract on or before ',
                              dateFormatterPdf(cDate),
                              ' for the sale of property at:'
                            ]
                          },
                          { text: [
                            lineIfEmpty(noValues ? null : contractSpecial?.salePropertySingleLine, FieldPlaceholderStyle.LongLocation),
                            formatBI('(‘*-*Sale Contract*-*’)')
                          ] }
                        ]
                      },
                      {
                        text: [
                          'for a price of not less than ',
                          lineIfEmpty(noValues ? null : contractSpecial?.purchaserSaleMinimumPrice, FieldPlaceholderStyle.Price),
                          ' or such lesser sum that the Purchaser accepts;'
                        ]
                      },
                      {
                        text: [
                          'the Sale Contract being unconditional on or before  ',
                          dateFormatterPdf(settleUnconditionDate),
                          ' (if applicable); and'
                        ]
                      },
                      {
                        text: [
                          'the Sale Contract settling on or before ',
                          dateFormatterPdf(sDate),
                          ' .'
                        ]
                      }
                    ]
                  }
                ]

              }),
              (!hideSaleNSettlement && !hideSettlement && formatBI('\n')),
              !hideSettlement && {
                stack: [

                  { text: 'Settlement', ...(clauseIsDecided ? {} : { style: 'boldEmphasis' }) },
                  {
                    text: [
                      {
                        text: [
                          ...insertIf(!clauseIsDecided, { text: '☐', style: 'checkbox', font: 'DejaVu', fontSize: 13, baselineOffset: 1.5 }),
                          '  Settlement under this Contract is conditional on the sale of the Purchaser’s property at:'

                        ]
                      }
                    ],
                    margin: [clauseIsDecided?0:-17,4,0,0]
                  },
                  fullWidthLineIfEmpty(noValues ? null : contractSpecial?.salePropertySingleLine, -6, 4.5),
                  { text: [

                    text(' pursuant to a contract entered into by the Purchaser dated '),
                    dateFormatterPdf(cDate),
                    text(':')
                  ] },
                  {
                    ...olLowerAlpha,...ol(
                      { text: [text('being unconditional on or before '), dateFormatterPdf(settleUnconditionDate), text(' (if applicable); and')] },
                      { text: [text(' settling on or before '), dateFormatterPdf(sDate), text('.')] }
                    )
                  }

                ]
              }
            ].filter(Predicate.isTruthy)
          },
          {
            stack: [
              'The Purchaser must:',
              {
                ...olLowerAlpha,
                ol: [
                  'use its best endeavours to satisfy SC 2.1; and',
                  'at the Vendor’s reasonable request, promptly inform the Vendor on the status of satisfaction of SC 2.1.'
                ]
              }
            ]
          },
          text('The Purchaser may waive this Special Condition by serving notice of its waiver on the Vendor.'),
          text('In the event that SC 2.1 is not satisfied or waived within the times agreed in SC 2.1, either party, and in the case of the Purchaser only where it has complied with SC 2.2, can immediately terminate this Contract by serving notice to the other party.'),
          {
            stack: [
              'In the event of termination of this Contract:',
              {
                ...olLowerAlpha,
                ol: [
                  'by a party pursuant to SC 2.4, all monies paid by or on behalf of the Purchaser must be repaid to the Purchaser; or ',
                  'by the Vendor pursuant to General Condition 16.2 as a result of the Purchaser failing to comply with SC 2.2, the Vendor is entitled to the Deposit without limitation to its other rights under this Contract.'
                ]
              }
            ]
          }
        ])
      }
    ]),
    ...extraAttrs
  });

  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect(subsectionItems),
    unbreakable: undefined,
    bookmark: bookmark,
    ...(templateAuctionMode ? { otherStackAttributes: { decoration: 'lineThrough' } } : undefined)
  });
}

export function contractSpecialConditionsSection(
  contractSpecial: ContractSpecialType | undefined,
  isOffer?: boolean,
  property?: {addresses?: SaleAddress[], titles?: SaleTitle[]},
  opts?: {
    templateAuctionMode: boolean
    templateContractMode?: boolean
  }

) {

  const { templateAuctionMode = false, templateContractMode } = opts??{};
  if (contractSpecial?.hideSection || (contractSpecial?.hideFinance && contractSpecial.hideSaleRequired) || templateAuctionMode) { // The default in the template contract in auction mode is now to not show these at all
    return [];
  }

  const sectionItems = [];
  sectionItems.push(financeSubsection(contractSpecial, isOffer, property??{}, { templateAuctionMode, templateContractMode }));
  sectionItems.push(purchaserSaleSubsection(contractSpecial, { isOffer, templateAuctionMode, templateContractMode }));
  return itemSection({
    itemNo: undefined,
    itemTitleParam: '',
    bookmark: 'bookmark_special',
    stackContent: [
      documentSubTitle('Special Conditions'.toUpperCase()),
      ...sectionItems
    ],
    superTitle: true,
    pageBreakBefore: true
  });
}

export function offerSpecialConditionsSection(
  itemNo: number,
  contractSpecial: ContractSpecialType | undefined,
  hasAdditionalConditions: boolean
) {
  if (contractSpecial?.hideSection || (contractSpecial?.hideFinance && contractSpecial.hideSaleRequired)) {
    return [];
  }
  const skipSection = contractSpecial?.financePermitted === false && contractSpecial?.purchaserSalePermitted === false;
  const showNothing = skipSection && !hasAdditionalConditions;
  if (showNothing) return [];

  if (skipSection) return itemSection({
    itemNo,
    itemTitleParam: 'Special Conditions'.toUpperCase(),
    bookmark: 'bookmark_special',
    stackContent: spaceStackLinesSideEffect([{ text: 'See Additional Conditions below that may apply.' }])
    // superTitle: true
  });

  const sectionItems = [];
  const doStrikeThrough = contractSpecial?.financeRequired === false && contractSpecial?.purchaserSaleRequired === false;
  sectionItems.push({ text: 'If selected, complete the relevant fields', decoration: doStrikeThrough ? 'lineThrough' : undefined });

  if (contractSpecial?.financePermitted !== false) sectionItems.push(offerFinanceSubsection(contractSpecial, contractSpecial?.financeRequired === false));
  if (contractSpecial?.purchaserSalePermitted !== false) sectionItems.push(offerPurchaserSaleSubsection(contractSpecial, contractSpecial?.purchaserSaleRequired === false));

  return itemSection({
    itemNo,
    itemTitleParam: 'Special Conditions'.toUpperCase(),
    bookmark: 'bookmark_special',
    stackContent: spaceStackLinesSideEffect(sectionItems)
    // superTitle: true
  });
}

export function offerAdditionalConditionsSection(
  itemNo: number,
  offerAdditionalConditions: string | undefined
) {
  if (!offerAdditionalConditions) return [];

  return itemSection({
    itemNo,
    itemTitleParam: 'Additional Conditions'.toUpperCase(),
    bookmark: ['bookmark_offerAdditionalConditions', 'subsection-offer-additional-conditions', ...fieldFocus('offerAdditionalConditions')],
    stackContent: spaceStackLinesSideEffect([
      { text: 'If you require any additional conditions be included in your offer, please set those out here:' },
      { text: offerAdditionalConditions }
    ])
  });
}
