import { FormInstance, SignerProxyAuthorityOptions, SignerProxyType, SigningPartySourceTypeToString, jointTypes } from '@property-folders/contract';
import {
  bookmarkAnchor,
  drawPlaceholder,
  drawUnderline,
  fieldsSpacing,
  generateCheckboxRowsV2, spaceStackLinesSideEffect
} from '../pdfgen';
import { uniqBy } from 'lodash';
import { FormTypes } from '../../yjs-schema/property/form';
import { signingTimeInTimezoneFromInstance } from '../pdfgen/display-transformations';
import { ISignatureSectionSigner } from '../pdfgen/sections/signatureSection';
import { buildFieldName, FieldType } from '../../signing/pdf-form-field';
import { mmToPoints } from '../pdfgen/measurements';
import { Predicate } from '../../predicate';
import { Content } from 'pdfmake/interfaces';
import { reaformsCharcoal } from '../../visual';
import { formatBI } from '../pdfgen/formatBI';
import { formatAct } from '../pdfgen/formatters/clauses';
import { LegalJurisdiction } from '../pdfgen/constants';
import { FieldPlaceholderStyle } from '../pdfgen/standards';

export const typeGroupSortOrder = [
  'Vendor',
  'Purchaser',
  'Salesperson',
  'Error'
];

const widths = {
  signature: 170,
  witness: 170,
  date: 140
};

function generateSigningNotesText(noWitness = false ) {
  return [{
    margin: [0, 16, 0, 0],
    stack: spaceStackLinesSideEffect([
      { text: 'Note:', italics: true },
      (noWitness ? null : { text: '- If a party signs electronically, that party does not need a witness and does not need to initial each page.', italics: true }),
      { text: `- Where signed by a person for a corporation, that person warrants they have authority to sign on behalf of the corporation pursuant to Section 126 or Section 127 of the ${formatAct(LegalJurisdiction.Commonwealth, 'CorporationsAct2001', { noItalics: true })}.`, italics: true }
    ].filter(Predicate.isTruthy))
  }];
}

function renderFakeSigningSection({ partyLabel }: {partyLabel: string}) {
  const signingAreaLineMargins = [...lineMargins];
  signingAreaLineMargins[1] = signingAreaLineMargins[1] + signatureHeight;
  return [
    {
      stack: [
        {
          margin: signingAreaLineMargins,
          canvas: [{
            type: 'polyline',
            lineWidth: mmToPoints(0.2),
            closePath: false,
            points: [{ x: 0, y: 0 }, { x: widths.signature, y: 0 }],
            lineColor: reaformsCharcoal
          }]
        },
        {
          text: partyLabel,
          style: 'boldEmphasis',
          margin: [0,0,0,fieldsSpacing/2]
        }

      ],
      verticalAlign: 'top'
    },
    {
      stack: [
        {
          margin: signingAreaLineMargins,
          canvas: [{
            type: 'polyline',
            lineWidth: mmToPoints(0.2),
            closePath: false,
            points: [{ x: 0, y: 0 }, { x: widths.witness, y: 0 }],
            lineColor: reaformsCharcoal
          }]
        },
        { text: 'Witness', style: 'boldEmphasis' }
      ],
      verticalAlign: 'top'
    },
    {
      stack: [
        {
          margin: signingAreaLineMargins,
          canvas: [{
            type: 'polyline',
            lineWidth: mmToPoints(0.2),
            closePath: false,
            points: [{ x: 0, y: 0 }, { x: widths.date, y: 0 }],
            lineColor: reaformsCharcoal
          }]
        },
        { text: 'Date', style: 'boldEmphasis' }
      ],
      verticalAlign: 'top'
    }
  ];
}

const lineMargins = [0, 0, 0, mmToPoints(2)];
const signatureHeight = 40;

export function renderSigningParty ({
  signerPartyAuthorityDisplay: signerPartyAuthority,
  placeholderId,
  signingPhrase,
  signingPartyIdentifier,
  partyType,
  partyTypeDataModel,
  authority,
  proxyAuthority,
  proxyForOriginalIdentifier,
  removing,
  noWitness
}: ISignatureSectionSigner, abovePartyInfo?: Content[]) {
  const isJointPartyType = jointTypes.includes(partyTypeDataModel);
  const showSigningAuthorityCheckboxes = !removing && partyType === 'Vendor' && !isJointPartyType && authority === undefined;
  const addPlaceholders = !!placeholderId;
  const placeholderIds = {
    signatureField: buildFieldName({ type: FieldType.FieldValue, fieldId: placeholderId }),
    signDateField: buildFieldName({ type: FieldType.FieldTimestamp, fieldId: placeholderId }),
    witnessField: buildFieldName({ type: FieldType.FieldWitness, fieldId: placeholderId })
  };

  const textSegs = signingPhrase?.split('[Unknown]');
  let signingPhraseWithPlaceholders: any[] = [signingPhrase ? formatBI(signingPhrase) : signingPhrase];
  if (textSegs && textSegs.length > 1) {
    signingPhraseWithPlaceholders = textSegs.map((stringSegment, i) => i === 0 ? [formatBI(stringSegment)] : [drawUnderline(FieldPlaceholderStyle.Default), formatBI(stringSegment)]).flat()?.filter(Predicate.isTruthy);
  }

  const proxyMode = Predicate.proxyNotSelf(proxyAuthority);

  const partyId: Content[] = [];

  if (proxyMode) {
    partyId.push({
      text: [signingPartyIdentifier, ' - ', SignerProxyAuthorityOptions[proxyAuthority]],
      style: 'boldEmphasis',
      margin: [0,0,0,fieldsSpacing/2]
    });
  }

  const mainPartyIdentifier = proxyMode ? proxyForOriginalIdentifier : signingPartyIdentifier;
  partyId.push({
    text: proxyMode ? `On behalf of ${mainPartyIdentifier}` : signerPartyAuthority ? [mainPartyIdentifier||' ', ' - ', signerPartyAuthority] : mainPartyIdentifier,
    ...(proxyMode ? {} : { style: 'boldEmphasis' }),
    margin: [0,0,0,fieldsSpacing/2]
  });

  if (signingPhrase) {
    partyId.push({
      text: signingPhraseWithPlaceholders,
      margin: [0,0,0,fieldsSpacing/2]
    });
  }
  partyId.push({
    text: partyType,
    style: 'boldEmphasis'
  });

  const result = [
    abovePartyInfo && abovePartyInfo.length && [{ stack: abovePartyInfo, colSpan: 3, margin: [0,fieldsSpacing*2,0,0] }, '', ''],
    [
      {
        fixedHeightSigningRow: true,
        stack: [
          addPlaceholders
            ? drawPlaceholder(placeholderIds.signatureField, signatureHeight)
            : drawUnderline(FieldPlaceholderStyle.Default),
          {
            margin: lineMargins,
            canvas: [{
              type: 'polyline',
              lineWidth: mmToPoints(0.2),
              closePath: false,
              points: [{ x: 0, y: 0 }, { x: (widths.signature+(noWitness?widths.witness+mmToPoints(3):0)), y: 0 }], // I don't really know how, but this seems to be the gap between fields. I thought it'd be either 2 mm or fieldsSpacing between fields based on margins, or perhaps 2* either of those, however, empirically 3mm seems to be it. This matters for making the sign here button line up with the underline
              lineColor: reaformsCharcoal
            }]
          },
          ...partyId
        ],
        verticalAlign: 'top',
        ...(noWitness?{ colSpan: 2 }:{})
      },
      (!noWitness ? {
        stack: [
          addPlaceholders
            ? drawPlaceholder(placeholderIds.witnessField, signatureHeight - 25, [0, 25, 0, 0])
            : drawUnderline(FieldPlaceholderStyle.Default),
          {
            margin: lineMargins,
            canvas: [{
              type: 'polyline',
              lineWidth: mmToPoints(0.2),
              closePath: false,
              points: [{ x: 0, y: 0 }, { x: widths.witness, y: 0 }],
              lineColor: reaformsCharcoal
            }]
          },
          { text: 'Witness', style: 'boldEmphasis' }
        ],
        verticalAlign: 'top'
      } : {}),
      {
        stack: [
          addPlaceholders
            ? drawPlaceholder(placeholderIds.signDateField, signatureHeight - 25, [0, 25, 0, 0])
            : drawUnderline(FieldPlaceholderStyle.Default),
          {
            margin: lineMargins,
            canvas: [{
              type: 'polyline',
              lineWidth: mmToPoints(0.2),
              closePath: false,
              points: [{ x: 0, y: 0 }, { x: widths.date, y: 0 }],
              lineColor: reaformsCharcoal
            }]
          },
          { text: 'Date', style: 'boldEmphasis' }
        ],
        verticalAlign: 'top'
      }
    ],
    showSigningAuthorityCheckboxes && [
      {
        colSpan: 3,
        ...generateCheckboxRowsV2([
          { label: 'Vendor is owner' },
          { label: 'As attorney for Vendor/executor/administrator' },
          { label: 'Vendor is mortgagee (in possession to sell)' },
          { label: 'As authorised director for Vendor' }
        ], { columns: 2, rowBottomMargin: 0 })
      }
    ]
  ].filter(Predicate.isTruthy);
  return result;
}

export function renderAddedParty(party: ISignatureSectionSigner, existing: ISignatureSectionSigner[], added: ISignatureSectionSigner[], removed: ISignatureSectionSigner[], documentName: string, documentDated: string) {
  return renderSigningParty(party, [
    { text: `${party?.partyIdentifier} advises that they have an interest in the property and it was an error to exclude them from the ${documentName} dated ${documentDated}.` }
  ]);
}

export function renderRemovedParty(party: ISignatureSectionSigner, existing: ISignatureSectionSigner[], added: ISignatureSectionSigner[], removed: ISignatureSectionSigner[], documentName: string, documentDated: string) {
  return renderSigningParty(party, [
    { text: `${party?.partyIdentifier} advises that they have no interest in the property and it was an error to include them in the ${documentName} dated ${documentDated}.` }
  ]);
}

export function hasAnyPartyActuallyVaried (signers: ISignatureSectionSigner[]) {
  const partiesMap = new Map<string, ISignatureSectionSigner[]>();
  for (const signer of signers) {
    const partyId = signer.originalPartySourceId ?? '';
    const existingMapEntry = partiesMap.get(partyId);
    if (!Array.isArray(existingMapEntry) || !existingMapEntry.length) {
      partiesMap.set(partyId, [signer]);
      continue;
    }
    const existingRemovals = existingMapEntry.reduce((acc,cv) => acc && !!cv.removing, false);

    if (existingRemovals && !signer.removing) {
      partiesMap.set(partyId, [signer]);
      continue;
    }
    if (existingRemovals && signer.removing) {
      existingMapEntry.push(signer);
      partiesMap.set(partyId, existingMapEntry);
      continue;
    }
    if (!existingRemovals && signer.removing) {
      continue;
    }
    // We should not get here from an empty array or no value, so we should be good to assume all
    // cases of removal management are handled

    const existingAdditions = existingMapEntry.reduce((acc,cv) => acc && !!cv.added, false);

    if (
      (existingRemovals && !signer.removing && signer.added)
      || (!existingAdditions && signer.added)
    ) {
      // handling a special case where signers have changed, but the party has not (salespersons)
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { added: _attrRemove, ...renewedParty } = signer; // Making this a continuing party
      partiesMap.set(partyId, [renewedParty]);
      continue;
    }

    // So the issue here, is that we might have new salespersons, but a carried over sales person
    // means the entire party still remains. However we may still wish to maintain this party, so
    // instead we just mark the existing added ones as continuing
    if (existingAdditions && !signer.added && !signer.removing) {
      const newMapEntry = existingMapEntry.map(addedParty => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { added: _attrRemove, ...renewedParty } = addedParty; // Making this a continuing party
        return renewedParty;
      });
      newMapEntry.push(signer);
      partiesMap.set(partyId, newMapEntry);
      continue;
    }
    existingMapEntry.push(signer);
    partiesMap.set(partyId, existingMapEntry);
  }
  return [...partiesMap.values()].reduce((acc,cv)=>{
    return acc || cv.reduce((acc,cv)=>(acc || !!cv.added || !!cv.removing), false);
  }, false);
}

export function buildSignatureSection(
  signers: ISignatureSectionSigner[],
  previousInstance?: FormInstance,
  opts?: {contractModeAndData?: {saleMethod: string | undefined}, finalBuildESigningMode?: boolean}): Content[] {
  if (!signers.length) {
    return ['bookmark_sign', 'bookmark_sign_!END'].map(bookmarkAnchor);
  }

  const auctioneerAlreadySigning = signers.filter(s=>s.proxyAuthority === SignerProxyType.Auctioneer).length > 1;
  // We're rating that if an auctioneer has already been added to the document, that we won't need
  // to also take into account the possibility that the auctioneer will find themselves signing
  // on behalf of other parties, as the auction has already concluded and signing is being set up
  // with this taken into account. If there are none however, we still need to consider the
  // possibility that this is going to be printed out, and thus still want this section
  const auctioneerSection = auctioneerAlreadySigning || !opts?.contractModeAndData || opts?.finalBuildESigningMode
    ? []
    : [
      { text: 'If applicable, executed by the *Auctioneer / Agent on behalf of *Vendor / Purchaser / Vendor & Purchaser:', margin: [0,fieldsSpacing,0,0] },
      { table: {
        dontBreakRows: true,
        widths: [ widths.signature, widths.witness, '*' ],
        heights: 70,
        body: [
          renderFakeSigningSection({ partyLabel: 'Auctioneer/Agent' })
        ]
      },
      layout: {
        defaultBorder: false
      } },
      { text: '* select the applicable option' }
    ];

  let interestedSigners = [...signers];
  interestedSigners = interestedSigners.sort((a, b) => {
    const agrpStr = SigningPartySourceTypeToString(a?.partyTypeEnum??0);
    const bgrpStr = SigningPartySourceTypeToString(b?.partyTypeEnum??0);
    const aOrder = typeGroupSortOrder.findIndex(testStr=>testStr === agrpStr);
    const bOrder = typeGroupSortOrder.findIndex(testStr=>testStr === bgrpStr);
    return aOrder - bOrder;
  });

  const interestedPartiesCheck = hasAnyPartyActuallyVaried(interestedSigners);
  const partyDiffingMode = interestedPartiesCheck;

  const allNoWitness = interestedSigners.every(s=>s.noWitness);

  const mapRowsToHeights = (body: ({fixedHeightSigningRow?: boolean})[][]) => {
    return body?.map(row => row.some(cell=>cell.fixedHeightSigningRow) ? 70 : 'auto');
  };

  if (!partyDiffingMode) {
    const body = interestedSigners
      .filter(s=>!s.removing)
      .map(sp=>renderSigningParty(sp))
      .flat(); // Removing would only appear on vendor
    return [
      bookmarkAnchor('bookmark_sign'),
      // ...tables
      {
        table: {
          dontBreakRows: true,
          widths: [ widths.signature, widths.witness, '*' ],
          heights: mapRowsToHeights(body),
          body
        },
        layout: {
          defaultBorder: false
        }
      },
      ...generateSigningNotesText(allNoWitness),
      ...auctioneerSection,
      bookmarkAnchor('bookmark_sign_!END')
    ];
  }

  const removedSigners = interestedSigners.filter(a=>a.removing);
  const addedSigners = interestedSigners.filter(a=>!a.removing&&a.added);
  const existingSigners = interestedSigners.filter(a=>!a.removing&&!a.added);

  const partiesRemoving = uniqBy(removedSigners, s=>s.originalPartySourceId);
  const partiesAdding = uniqBy(addedSigners, s=>s.originalPartySourceId);
  const partiesExisting = uniqBy(existingSigners, s=>s.originalPartySourceId);

  function andJoiningReducer (acc: string,cv: string,idx: number, arr: string[]) {
    let joinStr = ', ';
    if (idx+1===arr.length) joinStr = ' and ';
    return acc + joinStr + cv;
  }

  const namesRemoving = partiesRemoving.length ? partiesRemoving.map(signer=>signer.partyIdentifier).reduce(andJoiningReducer) : null;
  const namesAdded = partiesAdding.length ? partiesAdding.map(signer=>signer.partyIdentifier).reduce(andJoiningReducer) : null;
  const namesExisting = partiesExisting.length ? partiesExisting.map(signer=>signer.partyIdentifier).reduce(andJoiningReducer) : null;

  const contents: Content[] = [];

  const documentTypeName = (previousInstance && FormTypes[previousInstance?.formCode]?.label)||'EXAMPLE DOCUMENT';
  const documentSignedDate = (previousInstance && signingTimeInTimezoneFromInstance(previousInstance, true))||'DATE HERE';

  contents.push(...addedSigners.map(party=>renderAddedParty(party, existingSigners, addedSigners, removedSigners, documentTypeName, documentSignedDate)));
  contents.push(...removedSigners.map(party=>renderRemovedParty(party, existingSigners, addedSigners, removedSigners, documentTypeName, documentSignedDate)));

  if (existingSigners.length) {
    let existingAcknowledgement = `${namesExisting} acknowledge${partiesExisting.length > 1 ? '' : 's'} and agree with the above, and that from the date of this variation, `;
    const appendSections:string[] = [];
    if (removedSigners.length) {
      appendSections.push(`${namesRemoving} ${partiesRemoving.length > 1 ? 'are': 'is'} fully released from any rights or responsibilities under the ${documentTypeName}`);

    }
    if (addedSigners.length) {
      appendSections.push(`${namesAdded} ${partiesAdding.length > 1 ? 'are': 'is'} bound by the ${documentTypeName}`);
    }
    if (appendSections.length === 0) appendSections.push('');

    existingAcknowledgement += appendSections.reduce(andJoiningReducer)+'.';

    contents.push([[{ stack: [{ text: existingAcknowledgement, margin: [0,fieldsSpacing*2,0,0] }], colSpan: 3 }, '', '']]);

    contents.push(...existingSigners.map(sp=>renderSigningParty(sp)));
  }
  const bodyContents = contents.flat();
  const rVal = [
    bookmarkAnchor('bookmark_sign'),
    // ...tables
    {
      table: {
        dontBreakRows: true,

        widths: [widths.signature, widths.witness, '*' ],
        body: bodyContents
      },
      layout: {
        defaultBorder: false
      }
    },
    ...generateSigningNotesText(allNoWitness),
    ...auctioneerSection,
    bookmarkAnchor('bookmark_sign_!END')
  ];
  return rVal;

}
