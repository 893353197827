import { chunk, cloneDeep, difference } from 'lodash';
import { Maybe } from '../../../types/Utility';
import { chattelsOptionsSaa, cgtApplicationPersonOpts, tenancyTypeOpts, rentalPeriodOpts, poolSectionOpts, boolApplicableOpts, LegalJurisdiction, boolKnownOpts, chattelsOptionsContract, checkboxFontSize, ChattelOptions, tenantSaleContinueContractOpts, tenantSaleContinueOpts } from '../constants';
import { Predicate } from '../../../predicate';
import {
  blankField,
  fieldLabel,
  generateFieldTable,
  itemSection,
  knownPair,
  noborder,
  spaceStackLinesSideEffect,
  singleFieldTable,
  itemSubsection,
  generateCheckboxRows,
  freeTextArea,
  arbitraryFieldsRowTable,
  generateCheckboxInlineTextLine,
  confineWidthWithColumn,
  fieldBoolCheckboxes,
  fieldFocus,
  fieldsSpacing,
  freeTextContinuationArea,
  singleFieldOrFreeTextContinuationArea,
  generateCheckboxTextColumns,
  generateCheckboxContentColumns
} from '..';
import { Exclusions, Inclusions, LegacyTenancy, MaterialisedPropertyData, PoolCompliance, PoolComplyState, TenantAgreement, ContractSettlementType, ContractScheduleType, InclExclOptions, SaleTenancyContinuing } from '@property-folders/contract';
import { subsectionTitles } from '../doc-constants/sales-agency-agreement';
import { subsectionTitles as contractSubsectionTitles } from '../doc-constants/sale-contract';
import { minimumFontSize } from '../constants';
import { quadStateFilter } from '../../quadStateFilter';
import { QuadState } from '../../../types/QuadState';
import { formatBI } from '../formatBI';
import { formatAct, ReaformsContent } from '../formatters/clauses';
import { FieldPlaceholderStyle } from '../standards';
import { settlementSubsection } from './contractSettlement';
import { NIL } from 'uuid';
import { reaformsCharcoal } from '../../../visual';
import { Content } from 'pdfmake/interfaces';

export function settlementPeriodSection(settlementTime: Maybe<string>, isVariation?: boolean) {
  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: [settlementTime
      ? generateFieldTable(knownPair(
        {
          fieldName: 'Settlement period', fieldValue: [{ text: settlementTime, style: 'fieldContent' },
            {
              text: ' after Contract date.',
              style: 'tableFieldGeneralText'
            }], fieldColons: true, labelStyleOverride: undefined, contentStyleOverride: undefined, isVariation: isVariation
        }), undefined)
      : generateFieldTable([
        {
          border: noborder,
          text: [
            fieldLabel({ fieldName: 'Settlement period', fieldColons: true, styleOverride: undefined, isVariation: isVariation }),
            { text: '  30 days ', style: 'tableFieldOption' },
            { text: 'after Contract date or:  ', style: 'tableFieldGeneralText' }
          ]
        },
        blankField()
      ],['auto', '*'])],
    unbreakable: undefined,
    bookmark: ['subsection-settlement-period',...fieldFocus('sale.settlement')],
    isVariation
  });
}

export function particularsSection(
  itemNo: number,
  chattelObj: Maybe<{[k:string]: any, simple: string[]}>,
  exclusionObj: Maybe<{simple: string[]}>,
  encroach: Maybe<string>,
  notiWorks: Maybe<string>,
  specialTerms: Maybe<string>,
  cgtEnable: Maybe<boolean>,
  cgtPerson: Maybe<string>,
  gstWithholdEnable: Maybe<boolean>,
  poolCompliance: PoolCompliance | undefined,
  vendorGst: Maybe<boolean>,
  tenant: Maybe<object>,
  vendorWorksEnable: boolean | undefined,
  vendorWorks: Maybe<string>,
  encroachEnable: boolean | undefined,
  notiWorksEnable: boolean | undefined,
  specialTermsEnable: boolean | undefined,
  inputTaxed: boolean | undefined,
  moreData: {
    saaGstUnknowns: MaterialisedPropertyData['saaGstUnknowns'],
    tenantList: TenantAgreement[] | undefined,
    settlement: Maybe<ContractSettlementType>,
    inclExclOpts: InclExclOptions
  },
  { shortMode }: {
    shortMode?: boolean
  } = {}
) {
  const sectionItems = [];
  const settlementSection = settlementSubsection(moreData.settlement, { showTitle: true, isSaa: true, shortMode });

  sectionItems.push({ unbreakable: true, stack: [settlementSection, ...combinedInclusionsExclusionsSection({ exclusions: exclusionObj, inclusions: chattelObj, inclExclOpts: moreData.inclExclOpts }, {})] });

  sectionItems.push(...tenantSection(tenant, moreData.tenantList));
  sectionItems.push(...encroachSection({ enable: encroachEnable, content: encroach }));
  sectionItems.push(...notiWorksSection({ enable: notiWorksEnable, content: notiWorks }));
  sectionItems.push(...vendorWorksSection({ enable: vendorWorksEnable, content: vendorWorks }));
  sectionItems.push(...gstWithholdingSection({ gstWithholdEnable }, { shortMode }));
  sectionItems.push(...vendorGstSection({ vendorGst, inputTaxed, saaGstUnknowns: moreData.saaGstUnknowns }));
  sectionItems.push(...poolSection(poolCompliance?.present, poolCompliance?.complyState, poolCompliance?.nonComplyWorks, { saaMode: true }));

  return itemSection({ itemNo: itemNo, itemTitleParam: 'Property and Sale Particulars'.toUpperCase(), bookmark: 'bookmark_particulars', stackContent: sectionItems });
}

export function combinedInclusionsExclusionsSection(data: {exclusions: Exclusions | undefined, inclusions: Inclusions | undefined, inclExclOpts: InclExclOptions}, opts?: {isVariation?: boolean, title?: string, contractMode?: boolean}) {
  const { contractMode, isVariation, title } = opts??{};
  const { exclusions, inclusions, inclExclOpts } = data;
  const sectionItems = [{
    text: title || subsectionTitles.incAndEx,
    style: 'sectionSubTitle',
    margin: [0,0,0,2],
    fontSize: isVariation ? minimumFontSize : undefined
  }];

  const { cccDetail, cccEnable, simple: chattels } = inclusions || {};
  const exArray = exclusions?.simple?.filter(i=>!contractMode||i!==ChattelOptions.CompliantSmokeAlarm);
  const incArray = inclusions?.simple?.filter(i=>!contractMode||i!==ChattelOptions.CompliantSmokeAlarm);
  const options = contractMode ? chattelsOptionsContract : chattelsOptionsSaa;
  const cccBit = [];
  if (!Predicate.boolFalse(cccEnable) && !contractMode) {
    cccBit.push(
      singleFieldTable({
        fieldName: 'Consumer credit chattel details',
        fieldValue: (cccEnable && cccDetail) || '',
        isVariation,
        fieldPlaceholder: FieldPlaceholderStyle.Other
      }));
  }
  const showNoCheckboxes = !Array.isArray(exArray) && !Array.isArray(incArray);
  const noNa = showNoCheckboxes || (Array.isArray(exArray) && exArray.length > 0) || (Array.isArray(incArray) && incArray.length > 0);
  sectionItems.push(generateCheckboxTextColumns('None applicable', !noNa));
  const included = new Set(incArray);
  const excluded = new Set(exArray);
  const dualCols = true;
  // If these are hidden, they may still have a value, so we filter here where we generate the
  // display list such that it doesn't get shunted to the "other" list
  const postHideOpts = options
    .filter(a=>!inclExclOpts?.hideList?.includes(a.name));
  const triCells = postHideOpts
    .map(({ name, label, noExclude }) => {
      const isIncluded = included.has(name);
      const isExcluded = excluded.has(name);
      return [
        { text: [label], margin: [0,3,0,6] },
        { text: isIncluded?'☒':'☐', style: 'checkbox', font: 'DejaVu', fontSize: checkboxFontSize, ...isIncluded && { color: 'black' }, alignment: 'center' },
        ...(noExclude?[{}]:[{ text: isExcluded?'☒':'☐', style: 'checkbox', font: 'DejaVu', fontSize: checkboxFontSize, ...isExcluded && { color: 'black' }, alignment: 'center' }])
      ];
    });
  let rows = [];
  if (dualCols) {
    if (triCells.length % 2 !== 0) triCells.push([{ text: ' ' },{ text: ' ' },{ text: ' ' }]);

    const rowCount = triCells.length/2; // above push if odd should preclude this being 0.5
    const majorCols = chunk(triCells, rowCount);
    for (let i = 0; i < rowCount; i++) {
      rows.push([...majorCols[0][i], ...majorCols[1][i]]);
    }

  } else {
    rows = triCells;
  }
  const headerPart = [{}, { text: 'Incl.', margin: [0,0,0,6] }, { text: 'Excl.', margin: [0,0,0,6] }];
  const body = [
    [...(dualCols?cloneDeep(headerPart):[]), ...headerPart],
    ...rows
  ];
  if (postHideOpts?.length) {
    sectionItems.push({
      table: {
        widths: dualCols ? ['*', 'auto', 'auto', '*', 'auto', 'auto'] : ['*', 'auto', 'auto'],
        body
      },
      layout: {
        paddingRight: (i)=> dualCols&&i === 2 ? 8 : 2,
        paddingLeft: (i)=> dualCols&&i === 3 ? 8 : 2,
        paddingTop: ()=>-3,
        paddingBottom: ()=>-3,
        hLineWidth: ()=>0,
        vLineWidth: (i)=> dualCols&&i === 3 ? 0.5 : 0
      },
      unbreakable: true
    });
  }
  const otherOptionsMask = options.map(o=>o.name);
  const otherInclusions = difference(incArray, otherOptionsMask);
  const otherExclusions = difference(exArray, otherOptionsMask);

  (otherInclusions.length > 0 || showNoCheckboxes) && sectionItems.push(freeTextContinuationArea('Other Inclusions', showNoCheckboxes ? null : otherInclusions.join(', '), { linesIfEmpty: 2 }));
  (otherExclusions.length > 0 || showNoCheckboxes) && sectionItems.push(freeTextContinuationArea('Other Exclusions', showNoCheckboxes ? null : otherExclusions.join(', '), { linesIfEmpty: 2 }));

  sectionItems.push(...cccBit);

  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect(sectionItems),
    unbreakable: undefined,
    bookmark: ['subsection-IncEx', 'field_focus_exclusions', 'field_focus_chattels'],
    isVariation
  });
}

export const applicableFreeTextSection = (title: string, bookmark?: string|string[]|undefined, optionsVariant?: {'true': string, 'false': string} = boolApplicableOpts) => ({ enable, content, isVariation }: {enable?: boolean, content?: string, isVariation?: boolean}) => {
  const sectionItems = [];
  if (Predicate.emptyOrEqual(enable, true)) {
    sectionItems.push(freeTextArea({ content, linesIfEmpty: 3 }));
  }
  return itemSubsection({
    subsectionTitle: title,
    titleLineContent: {
      yesNoBox: true,
      currentVal: enable,
      trueLabel: optionsVariant['true'],
      falseLabel: optionsVariant['false']
    },
    subsectionContent: spaceStackLinesSideEffect(sectionItems, 1),
    bookmark: bookmark,
    isVariation
  });
};

export const encroachSection = applicableFreeTextSection(
  subsectionTitles.encroach,
  ['subsection-encroach', ...fieldFocus('encroach'), ...fieldFocus('encroachEnable')],
  boolKnownOpts
);

export const notiWorksSection = applicableFreeTextSection(
  subsectionTitles.notiWorks,
  ['subsection-noti-works', ...fieldFocus('notiWorks'), ...fieldFocus('notiWorksEnable')],
  boolKnownOpts
);

export const vendorWorksSection = applicableFreeTextSection(
  subsectionTitles.vendorWorks,
  ['subsection-vendor-works', ...fieldFocus('vendorWorks'), ...fieldFocus('vendorWorksEnable')],
);

export const specialWorksSection = applicableFreeTextSection(
  subsectionTitles.specialTerms,
  ['subsection-special-terms', ...fieldFocus('specialTerms')]
);

export function cgtSection(cgtEnable: Maybe<boolean>, cgtPerson: Maybe<string>, contractMode = false, isVariation?: boolean) {
  const sectionItems = [];
  if (Predicate.emptyOrEqual(cgtEnable, true)) {
    sectionItems.push({
      text: contractMode
        ? ['If sale price is $750,000 or more, the Vendor is required to deliver a tax clearance certificate prior to settlement and Clause 27 applies.']
        : 'If sale price is $750,000 or more, all Vendors will require a CGT clearance to avoid a withholding tax at settlement being paid to the ATO.',
      style: 'detailText' });
    !contractMode && sectionItems.push(singleFieldTable({
      fieldName: 'Application by', fieldValue: generateCheckboxInlineTextLine(
        cgtApplicationPersonOpts,
        cgtPerson,
        undefined,
        10.5
      ),
      isVariation
    }));
  }

  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.cgtWith,
      titleLineContent: {
        yesNoBox: true,
        currentVal: cgtEnable,
        trueLabel: 'Applicable',
        falseLabel: 'Not applicable'
      },
      subsectionContent: spaceStackLinesSideEffect(sectionItems, 1),
      unbreakable: undefined, bookmark: ['subsection-cgt-with', 'field_focus_cgtEnable', 'field_focus_cgtPerson'],
      isVariation
    }
  );
}

export function gstWithholdingSection({ gstWithholdEnable }:{gstWithholdEnable: Maybe<boolean>}, { isVariation, contractMode, shortMode }: {isVariation?: boolean, contractMode?: boolean, shortMode?: boolean} = {}) {
  const sectionItems = [
    { text: 'Is GST withholding at Settlement applicable?', margin: [0,0,0,0] },
    generateCheckboxRows([
      { selectMatch: 'false', label: 'No' },
      { selectMatch: 'true', label: 'Vendor’s Representative to provide notice, if required' }
    ], gstWithholdEnable == null ? [] : [`${gstWithholdEnable}`], 6),
    ...(shortMode? [] : [{
      text: `Note: the Purchaser will be required to withhold an amount from the Purchase Price and remit it to the ATO ${contractMode ? 'in accordance with General Condition 21 ' : ''}where the Vendor is registered or required to be registered for GST and the Property is: a ‘new residential premises’ (other than those created through a substantial renovation); or ‘potential residential land’.`,
      italics: true
    }])
  ];

  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.gstWith, subsectionContent: spaceStackLinesSideEffect(sectionItems), unbreakable: undefined, bookmark: ['subsection-gst-with', 'field_focus_gstWithholdEnable'],
      isVariation
    }
  );
}

export function poolSection(
  present?: boolean,
  compliance?: PoolComplyState,
  works?:string,
  renderOpts?: {contractMode?: boolean, isVariation?: boolean, saaMode?: boolean}) {
  const { contractMode = false, isVariation, saaMode } = renderOpts??{};
  const sectionItems = [];

  if (Predicate.emptyOrEqual(present, true)) {
    sectionItems.push(...(
      present
        ? [
          {
            text: [
              'The Vendor acknowledges that on or before Settlement, the Vendor must ensure that the Pool complies with all Pool safety features.'
            ],
            margin: [0,0,0,0],
            style: 'detailText'
          }
        ]
        : [
          {
            text: [
              'If there is a Pool on the Property, the Vendor must by law at or before Settlement ensure that the Pool complies with all Pool safety requirements.'
            ],
            margin: [0,0,0,0],
            style: 'detailText'
          }
        ]
    ));
    if (!saaMode && !contractMode) {
    // Because styles no longer seem to propogate down the text tree, we have to do this here
      const pre93Style = compliance === PoolComplyState.complyPre93 ? 'content':undefined;
      const post93Style = compliance === PoolComplyState.complyPost93 ? 'content':undefined;
      sectionItems.push(generateCheckboxRows([
        {
          label: formatBI(`Swimming Pool on Property (constructed pre 1 July 1993) - compliant with ${formatAct(LegalJurisdiction.SouthAustralia, 'PlanningDevelopmentAndInfrastructureAct2016')} as amended`, undefined, pre93Style),
          selectMatch: PoolComplyState.complyPre93
        },
        {
          label: formatBI(`Swimming Pool on Property (constructed post 1 July 1993) - compliant with ${formatAct(LegalJurisdiction.SouthAustralia, 'PlanningDevelopmentAndInfrastructureAct2016')} as amended`, undefined, post93Style),
          selectMatch: PoolComplyState.complyPost93
        },
        !contractMode && {
          label: 'Unsure, Vendor to engage qualified party to assess',
          selectMatch: PoolComplyState.unknown
        },
        { other: true, label: 'Non compliant - specify details', selectMatch: PoolComplyState.nonComply, content: compliance === PoolComplyState.nonComply ? works : undefined }
      ].filter(Predicate.isTruthy), Predicate.isNotNullish(compliance)  ?  [compliance] : [], 1));
    }
  }

  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.pool,
      titleLineContent: {
        yesNoBox: true, currentVal: present, trueLabel: poolSectionOpts.true, falseLabel: poolSectionOpts.false
      },
      subsectionContent: spaceStackLinesSideEffect(sectionItems),
      unbreakable: undefined,
      bookmark: ['subsection-pool', 'field_focus_pool'],
      isVariation
    }
  );
}

const quadStateRows = [
  { label: 'Yes', selectMatch: QuadState.True.toString() },
  { label: 'No', selectMatch: QuadState.False.toString() },
  { label: 'Unknown', selectMatch: QuadState.AssertUnknown.toString() }
];

export function vendorGstSection(
  { vendorGst, inputTaxed, saaGstUnknowns }: {vendorGst: Maybe<boolean>, inputTaxed?: boolean, saaGstUnknowns: MaterialisedPropertyData['saaGstUnknowns'] },
  { isVariation }: {isVariation?: boolean} = {}
) {
  const subSectionItems = [];

  const vendorReg = quadStateFilter(vendorGst, saaGstUnknowns?.vendorGst);
  const isInputTaxed = vendorReg === QuadState.NotSet ? QuadState.NotSet : quadStateFilter(inputTaxed, saaGstUnknowns?.inputTaxed);
  const mainOl = [
    { stack: spaceStackLinesSideEffect([

      { text: 'Is the Vendor registered, or required to be registered, for GST?', margin: [0, fieldsSpacing, 0, 0] },
      generateCheckboxRows(quadStateRows, [vendorReg.toString()], 6),
      { text: 'If ‘No’, GST is not applicable, and you can proceed to ‘Pool compliance’', italics: true }
    ]) }
  ];
  if (vendorReg !== QuadState.False) {
    mainOl.push({ stack: spaceStackLinesSideEffect([
      { text: ['If ‘Yes’ or ‘Unknown’ was selected, is the sale the supply of an existing ‘residential premises’ which is input taxed?'] },
      generateCheckboxRows(quadStateRows, [isInputTaxed.toString()], 6),
      ...(isInputTaxed === QuadState.True || isInputTaxed === QuadState.NotSet ? [{ text: 'If ‘Yes’, no GST is applicable.', italics: true }] : []),
      ...(isInputTaxed !== QuadState.True ? [{ text: 'If ‘No’ or ‘Unknown’, the sale may be considered a taxable supply. If it is a taxable supply, the parties will need to determine if the margin scheme is to apply, or if a relevant exemption applies (e.g. the supply of a going concern, or farmland supplied for a farming business). ', italics: true }] : [])
    ]) });
  }
  subSectionItems.push({
    ol: spaceStackLinesSideEffect(mainOl),
    margin: [0,fieldsSpacing,0,0]
  });

  if (isInputTaxed !== QuadState.True && vendorReg !== QuadState.False && (saaGstUnknowns?.inputTaxed || saaGstUnknowns?.vendorGst)) {
    subSectionItems.push({
      text: 'The Vendor advises it is seeking instructions in relation to the applicable GST treatment on the Sale.', style: 'content'
    });
  }

  subSectionItems.push({
    text: 'The Agent is not qualified to advise on the appropriate GST treatment. The Vendor and Purchaser must obtain their own independent professional advice in relation to the appropriate GST treatment.'
  });

  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.gstIssues,
      titleLineContent: undefined,
      subsectionContent: spaceStackLinesSideEffect(subSectionItems),
      unbreakable: undefined,
      bookmark: ['subsection-gst-rego', 'field_focus_vendorGst'],
      isVariation
    }
  );
}

export function individualTenancy(tenant: TenantAgreement, {
  index,
  contractMode = false,
  isVariation = false,
  multiple = false
}:{multiple?: boolean, index: number, contractMode?: boolean, isVariation?: boolean}) {

  const { type, tenantName, manageAgent, start, end, rentalValue, period, bondEnable, bondAmt, adviseTenant, saleContTenant, tenancyCont } = tenant || {};

  const underTitle = [];

  const continuationNotSet = !([SaleTenancyContinuing.Yes, SaleTenancyContinuing.No].includes(tenancyCont));

  if (contractMode) {
    underTitle.push({
      stack: [
        { text: 'Is the existing tenancy to continue after Settlement?', style: 'sectionText' },
        generateCheckboxInlineTextLine(tenantSaleContinueContractOpts, tenant?.tenancyCont, undefined, 10.5)

      ],
      margin: [0,0,0,0]
    });

    const propertyOrPremises = multiple
      ? `premises occupied under Tenancy ${index+1}`
      : 'Property';

    switch (tenancyCont) {
      case SaleTenancyContinuing.Yes:
        break;
      case SaleTenancyContinuing.No:
        underTitle.push({ text: `The Vendor must provide vacant possession of the ${propertyOrPremises} to the Purchaser at Settlement.` });
        break;
      default:
        underTitle.push({ text: `If "No", the Vendor must provide vacant possession of the ${propertyOrPremises} to the Purchaser at Settlement.` });
        underTitle.push({ text: 'If "Yes", complete the tenancy details set out below:' });

    }
  }

  const contractModeTenantIndent = 1;

  const tenantNamesItem = singleFieldOrFreeTextContinuationArea('Tenant(s)', tenantName || '', 2, isVariation);

  if (contractMode && multiple && tenancyCont === SaleTenancyContinuing.No) {
    underTitle.push({
      stack: spaceStackLinesSideEffect([tenantNamesItem],  contractModeTenantIndent)
    });
  }

  if (!contractMode || tenancyCont !== SaleTenancyContinuing.No) {
    const tenancyDetails: Content[] = [];
    tenancyDetails.push(singleFieldTable(
      {
        fieldName: 'Agreement type', fieldValue: generateCheckboxInlineTextLine(tenancyTypeOpts, type, undefined, 10.5),
        isVariation
      }
    ));

    if (!contractMode || multiple || continuationNotSet) tenancyDetails.push(tenantNamesItem);

    if (!contractMode) tenancyDetails.push(singleFieldTable({ fieldName: 'Managing agent', fieldValue: manageAgent, fieldPlaceholder: FieldPlaceholderStyle.Name }));
    type === 'fixed' || !type
      ? tenancyDetails.push(arbitraryFieldsRowTable({
        fields: [['Tenancy start date', start], [(type ? 'End date' : 'End date (if fixed term)'), end]],
        isVariation,
        fieldPlaceholder: FieldPlaceholderStyle.Date
      }))
      : tenancyDetails.push(confineWidthWithColumn(arbitraryFieldsRowTable({
        fields: [['Tenancy Start Date', start]],
        isVariation,
        fieldPlaceholder: FieldPlaceholderStyle.Date
      }), 240));

    //checkboxes cannot be inside the table or it messes up the height
    tenancyDetails.push({
      columns: [
        { ...arbitraryFieldsRowTable({
          fields: [['Rent amount', rentalValue]],
          fieldColons: [true, false],
          labelStyleOverride: [undefined, 'fieldFontGeneral'],
          contentStyleOverride: undefined,
          relativeSpace: undefined,
          isVariation,
          fieldPlaceholder: FieldPlaceholderStyle.Price
        }), width: 'auto' },
        { text: ' per', width: 20, fontSize: 10.5 },
        { ...generateCheckboxInlineTextLine(rentalPeriodOpts, period, undefined, 10.5, { baselineOffset: -2 }, { baselineOffset: -5 }) }
      ]
    });

    const bondAmount= Predicate.emptyOrEqual(bondEnable, true) ? [{
      ...arbitraryFieldsRowTable({
        fields: [['Bond amount', bondAmt]],
        fieldColons: [true, false],
        labelStyleOverride: [undefined, 'fieldFontGeneral'],
        contentStyleOverride: undefined,
        relativeSpace: undefined,
        isVariation,
        fieldPlaceholder: FieldPlaceholderStyle.Price
      }),
      width: bondAmt ? 'auto' : 170
    }] : [];

    tenancyDetails.push({
      columns: [
        { text: 'Bond with Consumer and Business Services: ', width: 'auto', style: 'tableFieldLabel' },
        { text: '', width: 5 },
        { ...fieldBoolCheckboxes(bondEnable, undefined, undefined, { baselineOffset: -2 }, { baselineOffset: -4 }), width: 'auto' },
        { text: '', width: 20 },
        ...(contractMode ? [] : bondAmount)
      ]
    });

    if (contractMode && tenancyCont !== SaleTenancyContinuing.No) {
      tenancyDetails.push({ text: 'The Vendor or Vendor\'s representative is to advise the tenant of the Purchaser\'s name and new rent payment details at least 14 days before Settlement.' });
    }

    underTitle.push({
      stack: spaceStackLinesSideEffect(tenancyDetails, contractMode ? contractModeTenantIndent : 0)
    });

  }

  if (!contractMode) {
    underTitle.push({ text: 'Vendor or Vendor\'s representative to advise tenant that the Property is for sale within 14 days of this Agreement.' });

    underTitle.push({
      unbreakable: true,
      stack: [
        { text: 'Is the existing tenancy to continue after Settlement?', style: 'sectionText' },
        generateCheckboxInlineTextLine(tenantSaleContinueOpts, tenant?.tenancyCont, undefined, 10.5)
      ],
      margin: [0,0,0,0]
    });

    switch (tenancyCont) {
      case SaleTenancyContinuing.Yes:
        underTitle.push({ text: 'Upon sale, the Vendor or Vendor\'s representative will advise the tenant of the Purchaser\'s name and new rent payment details, at least 14 days before Settlement.' });
        break;
      case SaleTenancyContinuing.No:
        underTitle.push({ text: 'If the tenancy agreement has not been effectively terminated already, the landlord will terminate the tenancy agreement and provide vacant possession to the Purchaser at Settlement.' });
        break;
      case SaleTenancyContinuing.Unsure:
        underTitle.push({ text: 'The tenancy agreement may continue after Settlement, depending on negotiations with the Purchaser.' });
        break;
      default:
        underTitle.push({ text: 'If "Yes", upon sale, the Vendor or Vendor\'s representative will advise the tenant of the Purchaser\'s name and new rent payment details, at least 14 days before Settlement.' });
        underTitle.push({ text: 'If "No", if the tenancy agreement has not been effectively terminated already, the landlord will terminate the tenancy agreement and provide vacant possession to the Purchaser at Settlement.' });
        underTitle.push({ text: 'If "Unsure", the tenancy agreement may continue after Settlement, depending on negotiations with the Purchaser.' });

    }

  }

  const sectionItems = spaceStackLinesSideEffect(underTitle);
  if (multiple) {
    return itemSubsection({
      isVariation,
      subsectionContent: sectionItems,
      subsectionTitle: `Tenancy ${index+1}`,
      unbreakable: true
    });
  }
  return sectionItems;
}

export function tenantSection(tenant?: LegacyTenancy, tenantList?: TenantAgreement[], contractMode = false, isVariation?: boolean) {
  const enable = tenant?.tenantEnable;
  const sectionItems = [];

  const multipleEntries = enable && tenantList?.length > 1;

  if (!Predicate.boolFalse(enable)) {
    const items = (tenantList && enable ? tenantList : [{
      id: NIL
    }]).map((individualTenant, index) => individualTenancy(individualTenant, { multiple: multipleEntries, index, contractMode, isVariation }));
    if (items.length) {
      sectionItems.push({
        stack: spaceStackLinesSideEffect(items)
      });
    }
  }

  const underHang = !Predicate.boolFalse(enable) ? spaceStackLinesSideEffect(sectionItems) : [];
  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.tenant,
      titleLineContent: { yesNoBox: true, currentVal: enable, trueLabel: boolApplicableOpts.true, falseLabel: boolApplicableOpts.false },
      subsectionContent: underHang,
      unbreakable: !multipleEntries, // The tenant section seems to be prone to putting a column at the page, and this breaks right hand page margins somehow. Making this unbreakable prevents the columns from occuring near the page boundary
      bookmark: ['subsection-tenancy', 'field_focus_saaTenant'],
      isVariation
    }
  );
}

export function encumbrancesSection(data: {encumbrances: ContractScheduleType}, opts?: {isVariation?: boolean}) {
  const { encumbrances } = data??{};
  const { anyNotDischarged, existingEncumbrances, newEncumbrances, newEncumbrancesText, noForm1NoCoolMatters, notApplicable } = encumbrances??{};
  const { isVariation }  = opts??{};

  const anySelected = anyNotDischarged || existingEncumbrances || newEncumbrances || notApplicable;

  const subsectionContent: ReaformsContent[] = [{ text: 'The Property is sold subject to the following Permitted Encumbrances:' }];

  const commonTextAttrs = { style: existingEncumbrances ? 'content' : undefined, fontSize: minimumFontSize, color: reaformsCharcoal };

  (!anySelected || anyNotDischarged) && subsectionContent.push(generateCheckboxTextColumns(
    'any Encumbrances detailed in the Form 1 as not being discharged prior to or at Settlement (/-/Note: only select if a Form 1 has been served or made available for perusal prior to signing the Contract, or if the Purchaser has cooling-off rights once the Form 1 is served/-/).',
    anyNotDischarged
  ));

  (!anySelected || existingEncumbrances) &&  subsectionContent.push(generateCheckboxContentColumns(
    freeTextContinuationArea(
      [
        { text: 'the following existing Encumbrance(s) (', ...commonTextAttrs },
        { text: 'Note: not required if top option selected', ...commonTextAttrs, italics: true },
        { text: '):', ...commonTextAttrs }
      ],
      existingEncumbrances&& noForm1NoCoolMatters,
      { linesIfEmpty: 2 }
    ),
    existingEncumbrances
  ));

  (!anySelected || newEncumbrances) && subsectionContent.push(generateCheckboxContentColumns(
    freeTextContinuationArea(
      [{ text: 'the following new Encumbrance(s) to be created after the Contract Date:', style: newEncumbrances ? 'content' : undefined,  fontSize: minimumFontSize, color: reaformsCharcoal }],
      newEncumbrances && newEncumbrancesText,
      { linesIfEmpty: 2 }
    ),
    newEncumbrances
  ));

  (!anySelected || notApplicable) && subsectionContent.push(generateCheckboxTextColumns(
    'not applicable.',
    notApplicable
  ));

  return itemSubsection({
    subsectionTitle: contractSubsectionTitles.permittedEncumbrances,
    subsectionContent: spaceStackLinesSideEffect(subsectionContent),
    bookmark: ['subsection-title-matters', ...fieldFocus('contractSchedule.noForm1NoCoolMatters')],
    isVariation
  });

}
